import React from "react";
import { getAssets, htmlParse } from "@unisearch/helpers";
import { LeadForm } from "..";
import styles from "./heroform.module.scss";

export const LeadSectionHeroForm = ({ pageData }) => {
  return (
    <section className={styles.section_heroform}>
      <div className={styles.heroform_bg_image_wrapper}>
        {pageData?.featureImageSrc && <div className={styles.overlay}></div>}
        <img
          src={
            pageData?.featureImageSrc ||
            getAssets("assets/images/background_with_circle.png")
          }
          alt={pageData?.title}
        />
      </div>
      <div className="container">
        <div
          className={`${styles.heading_wrapper} ${
            pageData?.featureImageSrc ? "" : styles.color_black
          }`}
        >
          {pageData?.pageTitle && (
            <>{pageData?.pageTitle ? htmlParse(pageData?.pageTitle) : ""}</>
          )}

          {pageData?.dynamicDescription && (
            <div className={styles.sub_title}>
              {pageData?.dynamicDescription
                ? htmlParse(pageData?.dynamicDescription)
                : ""}
            </div>
          )}
        </div>

        <div className={styles.form_wrapper}>
          <LeadForm pageData={pageData} />
        </div>
      </div>
    </section>
  );
};

export default LeadSectionHeroForm;
