import { authService } from "@unisearch/auth";
import { getAssets } from "@unisearch/helpers";
import AccountMenu from "@unisearch/theme/layout/headers/components/account_menu";
import HeaderSearch from "@unisearch/theme/layout/headers/header_search";
import { StudentNotificationComponent } from "@unisearch/theme/layout/student_admin/notification";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { CgMenu } from "react-icons/cg";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useMediaQuery } from "react-responsive";
import CompareComponent from "../components/compare";
import FavoriteComponent from "../components/favorite";
import styles from "./topmenu.module.scss";

const DashboardTopmenu = () => {
  const router = useRouter();
  const { asPath, query } = router;
  const profileInfo = authService.getProfileData();
  const [userData, setUserData] = useState(false);

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const toggleMobileMenu = () => {
    setOpenMobileMenu((prev) => !prev);
  };

  const isDesktop = useMediaQuery({
    query: "(min-width: 991px)",
  });

  useEffect(() => {
    if (!profileInfo) {
      authService.logout();
    } else {
      setUserData(profileInfo);
    }
  }, []);

  const generatePath = () => {
    const pathArray = asPath.split("/");
    pathArray.shift();
    return pathArray[2];
  };

  const RenderNavCard = (pathName) => {
    switch (pathName) {
      case "elearning":
        return ElearningNav();
      default:
        return StudentNav();
    }
  };

  const StudentNav = () => {
    return (
      <ProSidebar className="MobileSideMenu">
        <Menu>
          <MenuItem>
            <Link legacyBehavior href="/app/student">
              <a
                className={`${
                  asPath === `/app/student` ? `${styles.active}` : ""
                }`}
              >
                Dashboard
              </a>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link legacyBehavior href="/app/student/profile">
              <a
                className={`${
                  asPath.includes(`/app/student/profile`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Profile
              </a>
            </Link>
          </MenuItem>

          <MenuItem>
            <Link legacyBehavior href="/app/student/conversation/inbox">
              <a
                className={`${
                  asPath.includes(`/app/student/conversation`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Conversations
              </a>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link legacyBehavior href="/app/student/messages">
              <a
                className={`${
                  asPath.includes(`/app/student/messages`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Chat
              </a>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link legacyBehavior href="/app/student/offered-courses">
              <a
                className={`${
                  asPath.includes(`/app/student/offered-courses`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Recommended Courses
              </a>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link legacyBehavior href="/app/student/report">
              <a
                className={`${
                  asPath.includes(`/app/student/report`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Report
              </a>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link legacyBehavior href="/app/student/application">
              <a
                className={`${
                  asPath.includes(`/app/student/application`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Application
              </a>
            </Link>
          </MenuItem>

          <MenuItem>
            <Link legacyBehavior href="/app/student/counsellor">
              <a
                className={`${
                  asPath.includes(`/app/student/counsellor`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Counsellors
              </a>
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link legacyBehavior href="/app/student/mentor">
              <a
                className={`${
                  asPath.includes(`/app/student/mentor`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Mentors
              </a>
            </Link>
          </MenuItem> */}

          <MenuItem>
            <Link legacyBehavior href="/app/student/favorite">
              <a
                className={`${
                  asPath.includes(`/app/student/favorite`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Favorite’s
              </a>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link legacyBehavior href="/app/student/reading-list">
              <a
                className={`${
                  asPath.includes(`/app/student/reading-list`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Reading List
              </a>
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link legacyBehavior href="/app/student/appointment/mentor">
              <a
                className={`${
                  asPath.includes(`/app/student/appointment/mentor`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Appointments
              </a>
            </Link>
          </MenuItem> */}
          {/* <MenuItem>
            <Link legacyBehavior href="/app/student/download">
              <a
                className={`${
                  asPath.includes(`/app/student/download`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Download
              </a>
            </Link>
          </MenuItem> */}

          {/* <MenuItem>
            <Link legacyBehavior href="#">
              <a>Enquiries</a>
            </Link>
          </MenuItem> */}
          <MenuItem>
            <Link legacyBehavior href="/app/student/reviews">
              <a
                className={`${
                  asPath.includes(`/app/student/reviews`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Reviews
              </a>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link legacyBehavior href="/app/student/comments">
              <a
                className={`${
                  asPath.includes(`/app/student/comments`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Comments
              </a>
            </Link>
          </MenuItem>
        </Menu>
      </ProSidebar>
    );
  };

  const ElearningNav = () => {
    return (
      <ProSidebar className="MobileSideMenu">
        <Menu>
          <MenuItem>
            <Link legacyBehavior href="/app/student/elearning">
              <a
                className={`${
                  asPath === `/app/student/elearning` ? `${styles.active}` : ""
                }`}
              >
                Dashboard
              </a>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link legacyBehavior href="/app/student/elearning/courses">
              <a
                className={`${
                  asPath.includes(`/app/student/elearning/courses`)
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Courses
              </a>
            </Link>
          </MenuItem>
        </Menu>
      </ProSidebar>
    );
  };

  return (
    <>
      <nav className={styles.nav}>
        <div className={`${styles.wrapper}`}>
          <CgMenu className={styles.menu_icon} onClick={toggleMobileMenu} />
          <div className={styles.left}>
            <Link legacyBehavior href="/app/student">
              <a className={styles.logo}>
                <Image
                  src={getAssets("assets/logo-beta.webp")}
                  alt="UniSearch Logo"
                  blurDataURL={getAssets("assets/logo-beta.webp")}
                  placeholder="blur"
                  width={186}
                  height={30}
                />
              </a>
            </Link>
          </div>
          <div className={styles.right}>
            <HeaderSearch
              className={styles.search_main}
              placeholder="Search University, Courses, Exam Or Articles"
            />
            <div className={`relative`}>
              <Link legacyBehavior href="/">
                <a target="_blank" className={styles.message_icon}>
                  <BiLinkExternal style={{ minWidth: "20px" }} />
                </a>
              </Link>
              <span className={`uni-tooltip`}>Go to main website</span>
            </div>
            {/* <StudentNotificationComponent type="conversation" /> */}
            <StudentNotificationComponent />

            <div style={{ flexShrink: "0" }}>
              <FavoriteComponent />
            </div>
            <div style={{ flexShrink: "0" }}>
              <CompareComponent />
            </div>

            {userData ? (
              <AccountMenu userInfo={userData} />
            ) : (
              <Link legacyBehavior href="/signin">
                <a className="btn btn-secondary btn-md">Signin</a>
              </Link>
            )}
          </div>
        </div>
      </nav>

      {!isDesktop && (
        <div
          className={`${styles.mobile_menu_wrapper} ${
            openMobileMenu ? styles.menu_open : ""
          }`}
        >
          {RenderNavCard(generatePath())}
        </div>
      )}
    </>
  );
};

export default DashboardTopmenu;
