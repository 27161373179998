import React, { Fragment } from "react";
import Head from "next/head";
import FooterOne from "@unisearch/theme/layout/footers/footer_one";
import { getAssets } from "@unisearch/helpers";
import FixedPopup from "@unisearch/theme/layout/components/fixed_popup";
import { HeaderUniversity } from "../headers";
import Script from "next/script";

export const LayoutUniversityPage = ({
  children,
  title,
  metaTitle,
  metaKeywords,
  metaDescription,
  metaUrl,
  metaImage,
}) => (
  <Fragment>
    <Head>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="p:domain_verify" content="c7f4dc207b5a2b8446692325a0dfb112" />
      <meta
        name="facebook-domain-verification"
        content="025mfzyubxk5w3ws6cj9ra838kz8sz"
      />

      <title>
        {title ??
          "Welcome to UniSearch the complete platform for students and universities"}{" "}
        | UniSearch
      </title>
      <meta name="title" content={metaTitle} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="description" content={metaDescription} />

      {/* Opengraph */}
      <meta
        property="og:title"
        content={
          metaTitle ??
          "Welcome to UniSearch the complete platform for students and universities | myunisearch"
        }
      />
      <meta property="og:description" content={metaDescription ?? ""} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="myunisearch" />
      <meta property="og:url" content={metaUrl ?? "https://myunisearch.com/"} />
      <meta
        property="og:image"
        content={metaImage ?? getAssets("assets/logo-beta.png")}
      />

      {/* Twitter */}
      <meta
        name="twitter:title"
        content={
          metaTitle ??
          "Welcome to UniSearch the complete platform for students and universities | myunisearch"
        }
      />
      <meta
        name="twitter:description"
        content={
          metaDescription ??
          "Apply to Universities Abroad with expert guidance from existing students"
        }
      />
      <meta
        name="twitter:image"
        content={metaImage ?? getAssets("assets/logo-beta.png")}
      />
      <meta name="twitter:card" content="summary_large_image" />
    </Head>

    {/* Google Analytics */}
    <Script
      defer
      src="https://www.googletagmanager.com/gtag/js?id=G-CBM37S40N3"
    />
    <script
      defer
      dangerouslySetInnerHTML={{
        __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-CBM37S40N3', { page_path: window.location.pathname });
            `,
      }}
    />
    {/* Facebook Metas */}
    <script
      defer
      dangerouslySetInnerHTML={{
        __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '466355328286746');
            fbq('track', 'PageView');
            `,
      }}
    />
    <noscript>
      <img
        height="0"
        width="0"
        style={{ display: "none" }}
        src="https://www.facebook.com/tr?id=466355328286746&ev=PageView&noscript=1"
        alt=""
      />
    </noscript>

    <HeaderUniversity />
    <main>{children}</main>
    <FixedPopup />
    <FooterOne />
  </Fragment>
);

export default LayoutUniversityPage;
