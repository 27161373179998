import { CircularProgress, Grid } from "@mui/material";
import {
  getGeoInfo,
  htmlParse,
  swalError,
  swalMessage,
} from "@unisearch/helpers";
import API from "@unisearch/helpers/api";
import { Field, Form, Formik } from "formik";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Select from "react-select";
import { EncryptionStorage } from "@unisearch/helpers";
import styles from "./form.module.scss";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import Cookies from "js-cookie";

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    fontSize: "14px",
    padding: "5px",
    border: "1px solid #ebe9f1 !important",
    boxShadow: "0 !important",
    "&:hover": {
      border: "1px solid #ebe9f1 !important",
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = "#333";
    return {
      ...styles,
      fontSize: "13px",
      // maxHeight: "150px",
      color: isSelected ? "#F47F20" : "#B5B5C3",
      backgroundColor: isSelected
        ? "#F5F6FC"
        : isFocused
        ? "#F5F6FC"
        : styles.backgroundColor,
      "&:hover": {
        backgroundColor: "#F5F6FC",
        color: "#F47F20",
      },
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    overflow: "auto",
    // maxHeight: "150px",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#c2c2c2",
      fontSize: "13px",
    };
  },
};

export const LeadForm = ({
  pageData,
  showTitle = true,
  showSubTitle = true,
  buttonTitle,
  btnSizeFull = true,
  handleClose,
  pageSlug,
  columnSizeFull = false,
  onlyFormData,
  formTitle,
  formSubTitle,
  formType = "lead",
}) => {
  const router = useRouter();
  const formData = pageData?.formSchema ? pageData?.formSchema : onlyFormData;

  const init = {
    terms_and_condition: false,
  };

  const geolocationData = Cookies.get("default_country");

  const [initialState, setInitialState] = useState(init);
  const [countryName, setCountryName] = useState("");
  const [countryFrom, setCountryFrom] = useState(geolocationData);
  const [stateName, setStateName] = useState("");
  const [stateLabel, setStateLabel] = useState("");
  const [cityLabel, setCityLabel] = useState("");

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [defaultCountryCode, setDefaultCountryCode] = useState();
  useEffect(() => {
    setTimeout(() => {
      if (Cookies.get("default_country")) {
        setDefaultCountryCode(Cookies.get("default_country"));
      }
    }, 1500);
  }, [defaultCountryCode]);

  const isFormEnable = (values, errors = false) => {
    if (
      errors &&
      Object.keys(errors).length == 0 &&
      values?.terms_and_condition
    ) {
      return false;
    } else {
      return true;
    }
  };

  const emailTypeCheck = formData?.findIndex((item, i) => item.type == "email");

  let endpoint;
  if (formType === "event") {
    endpoint = "public/lead-contact-form/events-leads";
  }
  if (formType === "lead") {
    endpoint = "public/lead-contact-form";
  }

  const handleRegister = async ({ ...values }, actions) => {
    setLoading(true);
    await API.post(endpoint, {
      properties: {
        ...values,
        ...(countryFrom && { Country: countryFrom }),
      },
      emailExists: emailTypeCheck,
      slug: pageSlug ? pageSlug : router?.query.lead_page,
    }).then((resp) => {
      if (resp.error) {
        swalError("Something went wrong");
        setLoading(false);
      }
      if (!resp.error) {
        //set localstorage
        EncryptionStorage.setItem("leadFormSubmitted", true);
        swalMessage("Welcome!", "You have successfully registered");
        actions.resetForm();
        setLoading(false);
        if (handleClose) {
          handleClose();
        }
      }
    });
  };

  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const phoneRegExp = /^\+?[0-9]{1,3}(\s|-)?\(?[0-9]{1,}\)?(\s|-)?[0-9\-\.]{2,}$/;

  const validationSchema = (values) => {
    const errors = {};
    formData.forEach((item, i) => {
      if (item.isRequired == 1) {
        if (!values[item.label]) {
          errors[item.label] = "Required Field";
        }
      }

      if (item.type == "number") {
        if (!phoneRegExp.test(values[item.label])) {
          errors[item.label] = "Invalid phone number!";
        }
        if (values[item.label] && values[item.label].length <= 9) {
          errors[item.label] = "Minimum length must be at least 9 characters";
        }
        if (values[item.label] && values[item.label].length >= 16) {
          errors[item.label] = "Maximum length must be less than 16 characters";
        }
      }

      if (item.type == "email") {
        if (
          !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
            values[item.label]
          )
        ) {
          errors[item.label] = "Invalid email address!";
        }
      }
    });
    if (!values.terms_and_condition) {
      errors.terms_and_condition = "Required Field";
    }
    return errors;
  };

  const fetchState = async () => {
    const res = await API.get(`public/lead-contact-form/${countryName}`);
    if (res && !res.error) {
      setStateList(res.data);
    }
  };
  useEffect(() => {
    if (countryName) {
      fetchState();
    }
  }, [countryName]);

  const fetchCity = async () => {
    const res = await API.get(
      `public/lead-contact-form/state-info/${countryName}/${stateName}`
    );
    if (res && !res.error) {
      setCityList(res.data || []);
    }
  };

  useEffect(() => {
    if (stateName) {
      fetchCity();
    }
  }, [stateName]);

  return (
    <>
      <>
        {formTitle && (
          <div className={styles.form_title_wrapper}>
            <h2>
              <>{formTitle ? htmlParse(formTitle) : ""}</>
            </h2>
            {formSubTitle && <p>{formSubTitle}</p>}
          </div>
        )}

        {pageData?.formTitle && (
          <div className={styles.form_title_wrapper}>
            {showTitle && (
              <h2>
                <>{pageData?.formTitle ? htmlParse(pageData?.formTitle) : ""}</>
              </h2>
            )}
            {showSubTitle && <p>{pageData?.formDescription}</p>}
          </div>
        )}
      </>

      <Formik
        initialValues={initialState}
        enableReinitialize={true}
        validate={validationSchema}
        onSubmit={(values, actions) => {
          handleRegister(values, actions);
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors, touched }) => (
          <Form className="form" autoComplete="off">
            <Grid container columnSpacing={{ xs: 2, sm: 2, md: 4 }}>
              {formData?.map((item, i) => {
                if (item.type == "textarea") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      md={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      key={item.name}
                    >
                      <div className={styles.form_group}>
                        <label htmlFor={item?.name}>
                          {item?.label}{" "}
                          {item?.isRequired == 1 && (
                            <span className={styles.asterisk}>*</span>
                          )}
                        </label>
                        <textarea
                          id={item?.name}
                          name={item?.label}
                          placeholder={item?.placeholder}
                          value={values[item?.label] ?? ""}
                          onChange={(e) => {
                            setFieldValue(item?.label, e.target.value);
                            touched[item?.label] = true;
                          }}
                        />
                      </div>
                      {errors[item.label] && touched[item.label] ? (
                        <div className={styles.error_msg}>
                          {errors[item.label]}
                        </div>
                      ) : null}
                    </Grid>
                  );
                } else if (item.type == "select" || item.type == "country") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      md={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      key={item.name}
                    >
                      <div className={styles.form_group}>
                        <label htmlFor={item?.name}>
                          {item?.label}{" "}
                          {item?.isRequired == 1 && (
                            <span className={styles.asterisk}> *</span>
                          )}
                        </label>

                        <Select
                          id={item?.label}
                          instanceId={item?.label}
                          name={item?.label}
                          styles={selectStyles}
                          placeholder={item?.placeholder || "Please Select"}
                          options={item?.options ?? []}
                          onChange={(option) => {
                            if (item?.type == "country") {
                              setCountryName(option.value);

                              setFieldValue(stateLabel, "");
                              setFieldValue(cityLabel, "");

                              fetchState();
                              setCityList([]);
                            }
                            setFieldValue(item?.label, option.label);
                            touched[item?.label] = true;
                          }}
                          value={
                            item?.options
                              ? item?.options?.filter(
                                  (filtered) =>
                                    filtered.value === values[item.label]
                                )
                              : {}
                          }
                        />
                        {errors[item.label] && touched[item.label] ? (
                          <div className={styles.error_msg}>
                            {errors[item.label]}
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  );
                } else if (item.type == "state") {
                  setStateLabel(item?.label);
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      md={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      key={item.name}
                    >
                      <div className={styles.form_group}>
                        <label htmlFor={item?.name}>
                          {item?.label}{" "}
                          {item?.isRequired == 1 && (
                            <span className={styles.asterisk}> *</span>
                          )}
                        </label>
                        <Select
                          name={item?.label}
                          instanceId={item?.label}
                          id={item?.label}
                          styles={selectStyles}
                          placeholder={item?.placeholder || "Please Select"}
                          options={stateList ?? []}
                          onChange={(stat) => {
                            setFieldValue(item?.label, stat.label);
                            touched[item?.label] = true;
                            setStateName(stat.label);
                            fetchCity();
                          }}
                          value={
                            stateList
                              ? stateList?.filter(
                                  (filtered) =>
                                    filtered.value === values[item?.label]
                                )
                              : {}
                          }
                        />
                        {errors[item.label] && touched[item.label] ? (
                          <div className={styles.error_msg}>
                            {errors[item.label]}
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  );
                } else if (item.type == "city") {
                  setCityLabel(item?.label);
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      md={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      key={item.name}
                    >
                      <div className={styles.form_group}>
                        <label htmlFor={item?.name}>
                          {item?.label}{" "}
                          {item?.isRequired == 1 && (
                            <span className={styles.asterisk}> *</span>
                          )}
                        </label>
                        <Select
                          name={item?.label}
                          instanceId={item?.label}
                          id={item?.label}
                          styles={selectStyles}
                          placeholder={item?.placeholder || "Please Select"}
                          options={cityList ?? []}
                          onChange={(cty) => {
                            setFieldValue(item?.label, cty.value);
                            touched[item?.label] = true;
                          }}
                          value={
                            cityList
                              ? cityList?.filter(
                                  (filtered) =>
                                    filtered.value === values[item.label]
                                )
                              : {}
                          }
                        />
                        {errors[item.label] && touched[item.label] ? (
                          <div className={styles.error_msg}>
                            {errors[item.label]}
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  );
                } else if (item.type == "radio") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      md={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      key={item.name}
                    >
                      <div className={`${styles.form_group}`}>
                        <label
                          style={{ display: "block", marginBottom: "8px" }}
                        >
                          {item?.label}{" "}
                          {item?.isRequired == 1 && (
                            <span className={styles.asterisk}> *</span>
                          )}
                        </label>

                        <div className={styles.dynamic_form_checkbox_wrapper}>
                          {item?.options?.map((option, i) => {
                            return (
                              <label
                                key={`radio${i}`}
                                className="radio_container"
                              >
                                <span>{option?.label}</span>
                                <Field
                                  type="radio"
                                  name={item?.label}
                                  value={option?.label}
                                />
                                <span className="radio_checkmark"></span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                      <div className={styles.error_msg}>
                        {errors[item.label]}
                      </div>
                    </Grid>
                  );
                } else if (item.type == "checkbox") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      md={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      key={item.name}
                    >
                      <div className={`${styles.form_group}`}>
                        <label
                          style={{ display: "block", marginBottom: "8px" }}
                        >
                          {item?.label}{" "}
                          {item?.isRequired == 1 && (
                            <span className={styles.asterisk}> *</span>
                          )}
                        </label>

                        <div className={styles.dynamic_form_checkbox_wrapper}>
                          {item?.options?.map((option, i) => {
                            return (
                              <label
                                key={`checkbox${i}`}
                                className={`checkbox_container`}
                              >
                                <span>{option?.label}</span>
                                <Field
                                  type="checkbox"
                                  name={item?.label}
                                  value={option?.label}
                                />
                                <span className="checkbox_checkmark"></span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                      <div className={styles.error_msg}>
                        {errors[item?.label]}
                      </div>
                    </Grid>
                  );
                } else if (item.type == "number") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      md={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      key={item.name}
                    >
                      <div className={styles.form_group}>
                        <label htmlFor={item?.name}>
                          {item?.label}{" "}
                          {item?.isRequired == 1 && (
                            <span className={styles.asterisk}> *</span>
                          )}
                        </label>

                        <PhoneInput
                          autoFormat={false}
                          country={defaultCountryCode ?? ""}
                          value={values[item?.label] ?? ""}
                          onChange={(
                            _value,
                            country,
                            _event,
                            formattedValue
                          ) => {
                            setCountryFrom(country?.name);
                            setFieldValue(item?.label, formattedValue);
                            touched[item?.label] = true;
                          }}
                          name={item?.label}
                        />

                        {errors[item.label] && touched[item.label] ? (
                          <div className={styles.error_msg}>
                            {errors[item.label]}
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      md={
                        columnSizeFull
                          ? 12
                          : item?.columnSize
                          ? parseInt(item?.columnSize)
                          : 12
                      }
                      key={item.name}
                    >
                      <div className={styles.form_group}>
                        <label htmlFor={item?.name}>
                          {item?.label}{" "}
                          {item?.isRequired == 1 && (
                            <span className={styles.asterisk}> *</span>
                          )}
                        </label>
                        <input
                          id={item?.name}
                          name={item?.label}
                          placeholder={item?.placeholder}
                          type={item?.type}
                          value={values[item?.label] ?? ""}
                          onChange={(e) => {
                            setFieldValue(item?.label, e.target.value);
                            touched[item?.label] = true;
                          }}
                        />
                        {errors[item.label] && touched[item.label] ? (
                          <div className={styles.error_msg}>
                            {errors[item.label]}
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  );
                }
              })}
            </Grid>

            {pageData?.formNote && (
              <p className={styles.promise_text}>{pageData?.formNote}</p>
            )}

            <div className={styles.checkbox_container_wrapper}>
              <div className={styles.checkbox_with_error_wrapper}>
                <div className={styles.checkbox_wrapper}>
                  <label
                    className={`${styles.checkbox_lebel} checkbox_container`}
                    html="terms"
                  >
                    <Field
                      id="terms"
                      type="checkbox"
                      name="terms_and_condition"
                      className="checkbox disable-team team_values"
                      value={true}
                      checked={
                        values.terms_and_condition &&
                        values.terms_and_condition === true
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        setFieldValue(
                          "terms_and_condition",
                          event.target.checked
                        );
                        touched.terms_and_condition = true;
                      }}
                    />
                    <span
                      className="checkbox_checkmark"
                      style={{ marginLeft: "0px", marginTop: "2px" }}
                    ></span>
                    <span>I agree to UniSearch</span>
                  </label>

                  <Link legacyBehavior href="/terms-and-conditions">
                    <a className={styles.term_link}>Terms</a>
                  </Link>
                  <span>and</span>
                  <Link legacyBehavior href="/privacy-policy">
                    <a className={styles.term_link}>Privacy Policy.</a>
                  </Link>
                </div>

                {errors?.terms_and_condition && touched?.terms_and_condition ? (
                  <div className={styles.error_msg}>
                    {errors.terms_and_condition}
                  </div>
                ) : null}
              </div>
            </div>

            <button
              type="submit"
              className={`${styles.register_btn} btn btn-secondary btn-bg ${
                btnSizeFull ? "btn-full" : ""
              } ${
                loading || isFormEnable(values, errors)
                  ? styles.disabled
                  : "enable"
              } `}
              onClick={handleSubmit}
              disabled={loading || isFormEnable(values, errors) ? true : false}
            >
              {loading && <CircularProgress size="16px" color="inherit" />}
              {buttonTitle || "Register Now"}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default LeadForm;
