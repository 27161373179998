import React from "react";
import Link from "next/link";
import { getAssets } from "@unisearch/helpers";
import styles from "./header_mobile_top.module.scss";
import Image from "next/legacy/image";
import { FiX } from "react-icons/fi";

const HeaderMobileTop = (props) => {
  return (
    <>
      <div className={styles.top_nav}>
        <div className="container">
          <ul>
            <li className={styles.active}>
              <a href="/">Study</a>
            </li>
            <li>
              <a href="/elearning">eLearning </a>
            </li>
            <li>
              <a href="/accommodation">Accommodation</a>
            </li>
            <li>
              <a href="#">Jobs</a>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.mainHeader_wrapper}>
        <div className="container">
          <div className={styles.mainHeader}>
            <div className={styles.company_logo}>
              <a href="/" className={styles.logo}>
                <Image
                  src={getAssets("assets/logo-beta.webp")}
                  alt="UniSearch Logo"
                  blurDataURL={getAssets("assets/logo-beta.webp")}
                  placeholder="blur"
                  width={186}
                  height={30}
                />
              </a>
            </div>

            <div className={styles.right_options}>
              <Link legacyBehavior href="/profile/evaluation">
                <a
                  className={`btn btn-primary`}
                  style={{ padding: "5px", fontSize: "12px" }}
                >
                  Profile Evaluation
                </a>
              </Link>
            </div>

            <FiX
              onClick={() => {
                props.setOpen(false);
                // document.body.classList.remove("popOpen");
              }}
              className={styles.cross_icon}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderMobileTop;
