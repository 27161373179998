/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, IconButton, Menu } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { getAssets, whatTimeAgo } from "@unisearch/helpers";
import API from "@unisearch/helpers/api";
import { authAtom } from "@_recoil/_state";
import { useRouter } from "next/router";
import Pusher from "pusher-js";
import React, { useEffect, useState } from "react";
import { FiMail } from "react-icons/fi";
import { IoIosNotificationsOutline } from "react-icons/io";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactSVG } from "react-svg";
import "react-toastify/dist/ReactToastify.css";
import { useRecoilValue } from "recoil";
import styles from "./notification.module.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const StudentNotificationComponent = ({ type = false }) => {
  const [notification, setNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState("");
  const profileInfo = useRecoilValue(authAtom);
  const router = useRouter();
  const [list, setList] = useState([]);
  const [otherTotal, setOtherTotal] = useState(0);
  const [ConversationTotal, setConversationTotal] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification(false);
  };

  //handle open
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    document.body.classList.add("popOpen");
  };

  //handle close
  const handleClose = () => {
    setAnchorEl(null);
    document.body.classList.remove("popOpen");
  };

  const getNotification = async () => {
    await API.post("/student/filter-notification", {
      filter: {},
      sortOrder: "ASC",
      sortField: "id",
      pageNumber: page,
      pageSize: 10,
    }).then((res) => {
      if (!res.error && res?.data) {
        const { results } = res.data;
        setList((prev) => [...prev, ...results]);
        setOtherTotal(res.data?.total);
      } else {
        setHasMore(false);
      }
    });
  };

  // read all
  const readAllNotification = async () => {
    if (type === "conversation") {
      await API.get(`/student/student-notification/mark-all-read/email`).then(
        async (res) => {
          if (!res.error) {
            setPage(1);
            setList([]);
            await getNotification();
            setConversationTotal(0);
            setOtherTotal(0);
          }
        }
      );
    } else {
      await API.get(`/student/student-notification/mark-all-read/all`).then(
        async (res) => {
          if (!res.error) {
            setPage(1);
            setList([]);
            await getNotification();
            setConversationTotal(0);
            setOtherTotal(0);
          }
        }
      );
    }
  };

  // read one
  const readSingle = async (id, item) => {
    await API.get(`/student/student-notification/${id}`).then((res) => {
      if (!res.error) {
        setList([]);
        getNotification();
        if (item?.conversationId !== null) {
          handleClose();
          router.push(
            `/app/student/conversation/inbox/${item?.conversationId}`
          );
        } else if (item?.type === "profile evolution") {
          handleClose();
          router.push(`/app/student/report`);
        } else {
          router.push(`${item.link}`);
        }
      }
    });
  };

  useEffect(() => {
    getNotification();
    var pusher = new Pusher(`${process.env.NEXT_PUBLIC_PUSHER_KEY}`, {
      cluster: "ap2",
    });
    var channel = pusher.subscribe(`${process.env.NEXT_PUBLIC_PUSHER_CHANNEL}`);
    channel.bind(`student${profileInfo?.id}`, (data) => {
      setList([]);
      getNotification();
      setNotification(true);
      setNotificationMsg(data?.message?.message);
    });
  }, [page]);

  const getMore = () => {
    setPage(page + 1);
  };

  const getNotificationImage = (item) => {
    if (
      item == "favorite_university" ||
      item == "favorite_course" ||
      item == "favorite_courses"
    ) {
      return "assets/icons/Favorite.svg";
    } else if (
      item == "compare_university" ||
      item == "compare_course" ||
      item == "compare_courses"
    ) {
      return "assets/icons/Compare.svg";
    } else if (item == "article_bookmark") {
      return "assets/icons/Bookmark.svg";
    } else if (item == "review") {
      return "assets/icons/Review.svg";
    } else if (item == "prospectus") {
      return "assets/icons/Prospectus.svg";
    } else if (item == "course_guide") {
      return "assets/icons/Course.svg";
    } else if (item === "email") {
      return "assets/icons/Conversation.svg";
    } else if (item === "course offer") {
      return "assets/icons/Course.svg";
    } else if (item === "profile evolution") {
      return "assets/icons/user_colorable.svg";
    } else return "assets/icons/notification.svg";
  };

  return (
    <>
      <IconButton
        disableRipple
        color="inherit"
        onClick={handleClick}
        style={{ width: "30px", height: "30px" }}
      >
        {type === "conversation" ? (
          <Badge
            badgeContent={
              ConversationTotal > 0 ? ConversationTotal.toString() : null
            }
            color="warning"
          >
            <FiMail
              style={{
                color: "#333333",
                fontSize: "22px",
                strokeWidth: "1",
              }}
            />
          </Badge>
        ) : (
          <Badge
            badgeContent={otherTotal > 0 ? otherTotal.toString() : null}
            color="warning"
          >
            <IoIosNotificationsOutline
              style={{ color: "#333333", fontSize: "25px", strokeWidth: "2" }}
            />
          </Badge>
        )}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: styles.mui_paper, list: styles.mui_list }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <li>
          <div className={styles.notification_head}>
            <div className={styles.notification_head_left}>
              {type === "conversation" ? "Conversations" : "Notifications"}
            </div>
            <div className={styles.notification_head_right}>
              {list && list.length > 0 && (
                <button
                  className={styles.actions_btn}
                  onClick={readAllNotification}
                >
                  Mark all as read
                </button>
              )}

              {/* <div className={styles.dot}></div>
             <button className={styles.actions_btn}>Turn off alarts</button>*/}
              {type === "conversation" ? null : (
                <div className={styles.dot}></div>
              )}
              {type === "conversation" ? null : (
                <button
                  className={styles.actions_btn}
                  onClick={() => {
                    router.push("/app/student/settings");
                    handleClose();
                  }}
                >
                  Settings
                </button>
              )}
            </div>
          </div>
          <div id="scrollableDiv" className={styles.notification_list_wrapper}>
            {list && list.length > 0 ? (
              <InfiniteScroll
                dataLength={list?.length}
                hasMore={hasMore}
                next={getMore}
                loader={<small> Loading...</small>}
                scrollableTarget="scrollableDiv"
              >
                <ul className={styles.notification_lists}>
                  {list && list.length > 0
                    ? list
                        ?.sort(function(a, b) {
                          return b?.createdAt.localeCompare(a?.createdAt);
                        })
                        ?.map((item, i) => {
                          let url = getNotificationImage(item?.type);
                          return (
                            <li
                              key={i}
                              className={
                                item?.isViewed === 1
                                  ? styles.notification_list
                                  : styles.notification_list_active
                              }
                              onClick={() => {
                                readSingle(item?.id, item);
                              }}
                            >
                              <div className={styles.list_icon}>
                                <ReactSVG
                                  src={getAssets(
                                    url ?? "assets/icons/notification.svg"
                                  )}
                                />
                              </div>
                              <div className={styles.list_content}>
                                <h4 className={styles.list_title}>
                                  {item?.title}
                                </h4>
                                <div className={styles.list_text}>
                                  <span>{item?.content}</span>
                                  <span className={styles.heighlight}>
                                    {" "}
                                    {item?.userData?.fullName}
                                  </span>
                                </div>
                              </div>
                              <div className={styles.list_time}>
                                {whatTimeAgo(item?.createdAt)}
                              </div>
                            </li>
                          );
                        })
                    : "No Notification"}
                </ul>{" "}
              </InfiniteScroll>
            ) : (
              <div style={{ textAlign: "center" }}>No Notification Found !</div>
            )}
          </div>
        </li>
      </Menu>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={notification}
        autoHideDuration={3000}
        onClose={handleCloseNotification}
      >
        <Alert
          onClose={handleCloseNotification}
          severity="info"
          sx={{ width: "100%" }}
        >
          {notificationMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default StudentNotificationComponent;
