import { atom } from "recoil";

export const searchPopupState = atom({
  key: "searchpopup",
  default: {
    open: false,
    enablePopup: true,
    isOpenHomePagePopup: false,
  },
});
