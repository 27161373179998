import { getAssets } from "@unisearch/helpers";
import React from "react";
import Link from "next/link";
import { ReactSVG } from "react-svg";
import styles from "./header_campaign.module.scss";

export const HeaderCampaign = () => {
  return (
    <header className={styles.header_campaign}>
      <Link legacyBehavior href={"/"}>
        <a>
          <ReactSVG
            src={getAssets("assets/icons/logo_dark.svg")}
            className={styles.logo}
          />
        </a>
      </Link>
    </header>
  );
};

export default HeaderCampaign;
