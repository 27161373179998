import { useEffect, useState } from "react";
import Select from "react-select";
import API from "@unisearch/helpers/api";
import { selectStyles } from "./select_style.js";
import Skeleton from "@unisearch/common/skeleton/index.jsx";

const CourseFormSelectCountry = ({ countryValue, setCountryValue }) => {
  const [formatedData, setFormatedData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await API.get("/public/country/workable");
        if (res.data) {
          setFormatedData(
            res.data.map((item) => ({
              label: item.name,
              value: item.slug,
            }))
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSelectChange = (newValue) => {
    if (newValue === null) {
      setCountryValue(null);
    } else {
      setCountryValue(newValue);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Skeleton height={60} />
        </>
      ) : (
        <Select
          isClearable
          isSearchable
          styles={selectStyles}
          options={formatedData}
          placeholder="Select Country"
          onChange={handleSelectChange}
          value={countryValue}
        />
      )}
    </>
  );
};

export default CourseFormSelectCountry;
