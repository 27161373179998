import React from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import styles from "./header_university.module.scss";
import { getAssets } from "@unisearch/helpers";

export const HeaderUniversity = () => {
  return (
    <nav className={styles.header}>
      <div className="container">
        <Link legacyBehavior href="/university">
          <a className={styles.logo}>
            <Image
              src={getAssets("assets/logo-beta.webp")}
              alt="UniSearch Logo"
              blurDataURL={getAssets("assets/logo-beta.webp")}
              placeholder="blur"
              width={186}
              height={30}
            />
          </a>
        </Link>
      </div>
    </nav>
  );
};

export default HeaderUniversity;
