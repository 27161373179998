import React, { Fragment } from "react";
import { htmlParse } from "@unisearch/helpers";
import moment from "moment";
import { Grid } from "@mui/material";
import { getAssets } from "@unisearch/helpers";
import { ReactSVG } from "react-svg";
import styles from "./hero.module.scss";

export const LeadSectionHero = ({ pageData }) => {
  return (
    <section
      className={`${styles.section_hero} ${
        pageData?.featureImageSrc ? "" : styles.full_width
      }`}
    >
      <div className="container">
        <Grid container spacing={{ xs: 3, sm: 3, md: 6 }} alignItems="center">
          <Grid item xs={12} sm={12} md={pageData?.featureImageSrc ? 6 : 12}>
            <div className={`${styles.heading_wrapper} from_texteditor_wrapper`}>
             

              {pageData?.pageTitle && (
                <>{pageData?.pageTitle ? htmlParse(pageData?.pageTitle) : ""}</>
              )}

              {pageData?.dynamicDescription && (
                <div className={`${styles.sub_text} `}>
                  {pageData?.dynamicDescription
                    ? htmlParse(pageData?.dynamicDescription)
                    : ""}
                </div>
              )}

              {pageData?.template == "webinar" && (
                <>
                  {(pageData?.pageContent?.eventDate ||
                    pageData?.pageContent?.startTime ||
                    pageData?.pageContent?.endTime) && (
                    <div className={styles.time_wrapper}>
                      {pageData?.pageContent?.eventDate && (
                        <div className={styles.time_card}>
                          <ReactSVG
                            src={getAssets(
                              "assets/images/webinar/calendar.svg"
                            )}
                          />
                          <div className={styles.time_text}>
                            <div className={styles.time_text_bold}>Date</div>
                            <div className={styles.time_text_normal}>
                              {moment(pageData?.pageContent?.eventDate).format(
                                "Do MMMM, YYYY"
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {pageData?.pageContent?.startTime && (
                        <div className={styles.time_card}>
                          <ReactSVG
                            src={getAssets(
                              "assets/images/webinar/stopwatch.svg"
                            )}
                          />
                          <div className={styles.time_text}>
                            <div className={styles.time_text_bold}>Time</div>
                            <div className={styles.time_text_normal}>
                              {moment(pageData?.pageContent?.startTime).format(
                                "LT"
                              )}{" "}
                              {pageData?.pageContent?.endTime && (
                                <>
                                  to{" "}
                                  {moment(
                                    pageData?.pageContent?.endTime
                                  ).format("LT")}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}

              <div className={styles.btn_wrapper}>
                <a href={"#form"} className={`btn btn-secondary btn-bg`}>
                  Book Now
                </a>
              </div>
            </div>
          </Grid>

          {pageData?.featureImageSrc && (
            <Grid item xs={12} sm={12} md={6}>
              <div className={styles.hero_image_wrapper}>
                <img src={pageData?.featureImageSrc} alt={pageData?.title} />
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </section>
  );
};

export default LeadSectionHero;
