import Link from "next/link";
import { FiX } from "react-icons/fi";
import styles from "./cookie.module.scss";

export const CookiePopup = ({ handleCookiePopup }) => {
  return (
    <>
      <div className={styles.wrapper}>
        <button className={styles.close} onClick={() => handleCookiePopup()}>
          <FiX />
        </button>
        <div className={styles.text}>
          <span>
            This site uses cookies. By continuing to use this website, you agree
            to their use. For details, please check our.
          </span>{" "}
          <Link href="/terms-and-conditions" className={styles.link}>
            Privacy Policy
          </Link>
        </div>
        <button
          onClick={() => handleCookiePopup()}
          className="btn btn-secondary btn-sm"
        >
          I Accept
        </button>
      </div>
    </>
  );
};
export default CookiePopup;
