import { Grid } from "@mui/material";
import { hexToRGB } from "@unisearch/helpers";
import { Fragment } from "react";
import styles from "./features.module.scss";

export const LeadSectionFeatures = ({ pageData }) => {
  let full_col = "";
  if (
    !pageData?.pageContent?.featureTitle &&
    !pageData?.pageContent?.featureSubtitle
  ) {
    full_col = "full_col";
  }

  const cardLength = pageData?.pageContent?.featuresSection?.length;
  return (
    <Fragment>
      {pageData?.pageContent?.featuresSection?.length > 0 ? (
        <Fragment>
          {pageData?.pageContent?.featuresSection?.map((secData, i) => {
            return (
              <section key={i}>
                <div className="container">
                  <div className={`${styles.description_wrapper}`}>
                    <div
                      className={styles.description_title_wrapper}
                      // style={{ order: i % 2 == 0 ? "1" : "2" }}
                    >
                      {secData?.featureSubtitle && (
                        <h4 className={styles.description_sub_title}>
                          {secData?.featureSubtitle}
                        </h4>
                      )}

                      {secData?.featureTitle && (
                        <h2 className={styles.description_title}>
                          {secData?.featureTitle}
                        </h2>
                      )}
                    </div>

                    {secData?.featuresCard?.length > 0 && (
                      <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        // style={{ order: i % 2 == 0 ? "2" : "1" }}
                      >
                        {secData.featuresCard?.map((item, i) => {
                          const bgColor = hexToRGB(item?.cardColor);
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={4}
                              key={`featureCard${i}`}
                            >
                              <div
                                className={`${styles.description_card}`}
                                style={{
                                  color: `${item?.cardColor}`,
                                  backgroundColor: `rgba(${bgColor}, .05)`,
                                }}
                              >
                                {item?.cardIconSrc && (
                                  <div
                                    className={
                                      styles.description_card_img_wrapper
                                    }
                                  >
                                    <img
                                      src={item?.cardIconSrc}
                                      alt="featured icon"
                                      style={{ maxWidth: "30px" }}
                                    />
                                  </div>
                                )}
                                {item?.cardDescription && (
                                  <div className={styles.description_card_text}>
                                    {item?.cardDescription}
                                  </div>
                                )}
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    )}
                  </div>
                </div>
              </section>
            );
          })}
        </Fragment>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default LeadSectionFeatures;
