import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { Fragment, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import styles from "./footer_one.module.scss";

const Collapse = dynamic(() => import("@mui/material/Collapse"), {
  ssr: false,
});
const SocialLinks = dynamic(
  () => import("@modules/study/common/social_links"),
  {
    ssr: false,
  }
);

const address = (
  <>
    Suite 98 Level 3, 515 Kent Street, <br />
    Sydney, NSW 2000
  </>
);

const data = [
  {
    id: 1,
    name: "About UniSearch",
    children: [
      { link: "/who-we-are", title: "Who We Are" },
      { link: "/vision-mission", title: "Vision & Mission" },
      { link: "/team", title: "Team" },
      {
        link: "/contact-us",
        title: "Contact Us",
      },
    ],
  },
  {
    id: 2,
    name: "Join Our Team",
    children: [
      {
        link: process.env.NEXT_PUBLIC_DOMAIN_MENTOR,
        title: "Become a Mentor",
        linkOutside: true,
      },
      {
        link: process.env.NEXT_PUBLIC_DOMAIN_COUNSELLOR,
        title: "Counsellor Login",
        linkOutside: true,
      },
      {
        link: process.env.NEXT_PUBLIC_DOMAIN_UNIVERSITY,
        title: "University Login",
        linkOutside: true,
      },
      { link: "/career", title: "Career" },
    ],
  },
  {
    id: 3,
    name: "Key Features",
    children: [
      { link: "/explore-university", title: "Study Abroad" },
      { link: "/elearning", title: "Online Learning" },
      { link: "/accommodation", title: "Accommodation" },
      { link: "#", title: "Internships", type: "p", coming: true },
    ],
  },
  {
    id: 4,
    name: "Connect with Us",
    children: [
      {
        link: "#",
        type: "jsx",
        title: address,
      },
    ],
    members: {
      title: "Members",
      logos: ["/assets/members-logo/nafsa-logo-.webp"],
    },
  },
];

export const FooterOne = (props) => {
  const [openItem, setOpen] = useState([]);
  const handleCollapse = (val) => {
    const item = [...openItem];
    const index = item.indexOf(val);
    if (index > -1) {
      item.splice(index, 1);
    } else {
      item.push(val);
    }
    setOpen(item);
  };

  return (
    <Fragment>
      {props.footer_with_evaluation && !props.userData && (
        <section className={styles.section_evaluation}>
          <div className="container">
            <div className={styles.evaluation_wrapper}>
              <div className={`${styles.title_wrapper}`}>
                <h2 className={`${styles.title} fw400`}>
                  Ready to Get{" "}
                  <span className={`${styles.mark} mark `}>Started?</span>
                </h2>

                <p className={`${styles.sub_title} sub_title`}>
                  Help us find the best study abroad courses and universities
                  for you with just a few simple steps.
                </p>
              </div>
              <Link legacyBehavior href="/profile/evaluation">
                <a className="btn btn-bg btn-white">Profile Evaluation</a>
              </Link>
            </div>
          </div>
        </section>
      )}

      <footer>
        <div
          className={`${styles.footer_main} ${
            props.footer_with_evaluation && !props.userData
              ? styles.padding_big
              : ""
          }`}
        >
          <div className="container-extend container">
            <div className={styles.row}>
              {data.map((item) => {
                return (
                  <div key={item.id}>
                    <h5 className={styles.fnav_title}>
                      <span>{item.name}</span>
                    </h5>
                    <ul className={styles.fnav_list}>
                      {item.children.map((child, i) => (
                        <li key={i}>
                          {child.type == "jsx" ? (
                            <>
                              <SocialLinks />
                              {/* <AddressPopup
                                customClasses={{
                                  root: styles.root,
                                  country: styles.country,
                                  dot: styles.dot,
                                }}
                              /> */}
                            </>
                          ) : (
                            <>
                              {child.type == "p" ? (
                                <span className={`relative`}>
                                  {child.title}
                                  {child.coming && (
                                    <span className="uni-tooltip">
                                      Coming Soon
                                    </span>
                                  )}
                                </span>
                              ) : (
                                <>
                                  {child.linkOutside ? (
                                    <a
                                      href={child.link}
                                      target="_blank"
                                      rel="nofollow"
                                    >
                                      <span>{child.title}</span>
                                    </a>
                                  ) : (
                                    <Link legacyBehavior href={child.link}>
                                      <a>
                                        <span>{child.title}</span>
                                      </a>
                                    </Link>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                    {item?.members && (
                      <>
                        <h5 className={`${styles.fnav_title} !mb-2`}>
                          <span>{item?.members?.title}</span>
                        </h5>
                        {item?.members?.logos?.length > 0 && (
                          <div className="flex gap-2">
                            {item?.members?.logos?.map((member, i) => {
                              return (
                                <Image
                                  src={member}
                                  width={70}
                                  height={70}
                                  key={i}
                                  alt="partner"
                                />
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div
          className={`${styles.footer_main} ${styles.mobile} ${
            props.footer_with_evaluation && !props.userData
              ? styles.padding_big
              : ""
          }`}
        >
          <div className="container-extend">
            <div className={styles.row}>
              {data.map((item) => {
                return (
                  <div key={item.id}>
                    <h5
                      className={styles.fnav_title}
                      onClick={(e) => handleCollapse(item.id)}
                    >
                      <span>{item.name}</span>
                      {openItem.indexOf(item.id) > -1 ? (
                        <FiChevronUp />
                      ) : (
                        <FiChevronDown />
                      )}
                    </h5>
                    <Collapse
                      in={openItem.indexOf(item.id) > -1}
                      timeout="auto"
                      unmountOnExit
                    >
                      <ul className={styles.fnav_list}>
                        {item.children.map((child, i) => (
                          <li key={i}>
                            {child.type == "jsx" ? (
                              <>
                                <SocialLinks />
                                {/* <AddressPopup
                                  customClasses={{
                                    root: styles.root,
                                    country: styles.country,
                                    dot: styles.dot,
                                  }}
                                /> */}
                              </>
                            ) : (
                              <>
                                {child.type == "p" ? (
                                  <span className={`relative`}>
                                    {child.title}
                                    {child.coming && (
                                      <span className="uni-tooltip">
                                        Coming Soon
                                      </span>
                                    )}
                                  </span>
                                ) : (
                                  <>
                                    {child.linkOutside ? (
                                      <a
                                        href={child.link}
                                        target="_blank"
                                        rel="nofollow"
                                      >
                                        <span>{child.title}</span>
                                      </a>
                                    ) : (
                                      <Link legacyBehavior href={child.link}>
                                        <a>
                                          <span>{child.title}</span>
                                        </a>
                                      </Link>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </li>
                        ))}
                      </ul>

                      {item?.members && (
                        <>
                          <h5 className={`${styles.fnav_title} !mb-2`}>
                            <span>{item?.members?.title}</span>
                          </h5>
                          {item?.members?.logos?.length > 0 && (
                            <div className="flex gap-2">
                              {item?.members?.logos?.map((member, i) => {
                                return (
                                  <Image
                                    src={member}
                                    width={70}
                                    height={70}
                                    key={i}
                                    alt="member"
                                  />
                                );
                              })}
                            </div>
                          )}
                        </>
                      )}
                    </Collapse>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className={styles.footer_bottom}>
          <div className="container">
            <div className={styles.container}>
              <div className={styles.bottom_left}>
                <p>
                  Copyright © {new Date().getFullYear()} UniSearch &#8211; All
                  rights reserved.
                </p>
              </div>
              <div className={styles.bottom_right}>
                <Link legacyBehavior href="/terms-and-conditions">
                  <a>Terms of Service</a>
                </Link>
                <span>
                  <span className={styles.fiiter_link}> Developed by :</span>{" "}
                  <a
                    style={{ padding: 0, opacity: "100%" }}
                    href="https://www.m4yours.com/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    M4YOURS IT
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default FooterOne;
