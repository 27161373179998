import { authAtom } from "@_recoil/_state";
import Link from "next/link";
import { useEffect, useState } from "react";
import { MdSwapCalls } from "react-icons/md";
import { useRecoilValue } from "recoil";
import styles from "./compare.module.scss";

const CompareComponent = () => {
  const userDatas = useRecoilValue(authAtom);
  const [userData, setUserData] = useState();
  const [compareState, coutnCompare] = useState(0);

  useEffect(() => {
    if (userDatas) {
      setUserData(userDatas);
    }
  }, [userDatas]);

  useEffect(() => {
    if (
      userData &&
      (userData.compareCourseList || userData.compareUniversityList)
    ) {
      let compNum = 0;
      if (userData.compareCourseList && userData.compareCourseList.length) {
        compNum += userData.compareCourseList.length ?? 0;
      }
      if (
        userData.compareUniversityList &&
        userData.compareUniversityList.length
      ) {
        compNum += userData.compareUniversityList.length ?? 0;
      }
      coutnCompare(compNum);
    } else {
      coutnCompare(0);
    }
  }, [userData]);

  return (
    <div className={styles.compare_dropdown_parent}>
      <div className={styles.compare}>
        <MdSwapCalls
          style={{
            transform: "rotate(90deg)",
          }}
        />
        <span className={styles.compare_num}>
          {compareState > 9 ? `9+` : compareState}
        </span>
      </div>
      {compareState > 0 && (
        <div className={styles.compare_dropdown}>
          {userData &&
            userData.compareUniversityList &&
            userData.compareUniversityList.length > 0 && (
              <Link href="/compare/university">
                <span className={styles.comp_name}>University</span>
                <span className={styles.comp_count}>
                  {parseInt(userData && userData.compareUniversityList.length)}
                </span>
              </Link>
            )}
          {userData &&
            userData.compareCourseList &&
            userData.compareCourseList.length > 0 && (
              <Link href="/compare/course">
                <span className={styles.comp_name}>Course</span>
                <span className={styles.comp_count}>
                  {parseInt(userData && userData.compareCourseList.length)}
                </span>
              </Link>
            )}
        </div>
      )}
    </div>
  );
};

export default CompareComponent;
