import { getAssets } from "@unisearch/helpers";
import { useAuthActions } from "@_recoil/_actions";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import styles from "./account_menu.module.scss";
import dynamic from "next/dynamic";
const Menu = dynamic(() => import("@mui/material/Menu"), {
  ssr: false,
});

export const AccountMenu = ({ userInfo }) => {
  const authActions = useAuthActions();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  //handle open
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    document.body.classList.add("popOpen");
  };

  //handle close
  const handleClose = () => {
    setAnchorEl(null);
    document.body.classList.remove("popOpen");
  };

  //Logout
  const logout = () => {
    if (
      userInfo &&
      (userInfo.userTypeSlug == "mentor" || userInfo.userTypeSlug == "student")
    ) {
      // authService.logout("/signin");
      authActions.logout("signin");
    } else {
      // authService.logout("/partner-login");
      authActions.logout("partner-login");
    }
  };

  //render Profile Image
  const renderProfileImage = (data) => {
    if (data && data.userTypeSlug == "university") {
      const logo = data?.university?.logo?.src;
      return (
        <Fragment>
          {logo && logo != "" ? (
            <img src={logo} alt="avatar" />
          ) : (
            data?.firstName?.charAt(0)
          )}
        </Fragment>
      );
    } else if (
      data &&
      (data.userTypeSlug == "student" || data.userTypeSlug == "mentor")
    ) {
      const ProfileImage = data?.profileImageSrc;
      return (
        <Fragment>
          {ProfileImage && ProfileImage != "" ? (
            <img src={ProfileImage} alt="avatar" />
          ) : (
            data && data?.firstName?.charAt(0)
          )}
        </Fragment>
      );
    } else if (data && data.userTypeSlug == "counsellor") {
      const ProfileImage = data?.profileImageSrc;
      return (
        <Fragment>
          {ProfileImage && ProfileImage != "" ? (
            <img src={ProfileImage} alt="avatar" />
          ) : (
            data && data?.firstName?.charAt(0)
          )}
        </Fragment>
      );
    }

    return (
      <img src={getAssets("assets/student-avatar-small.png")} alt="Avatar" />
    );
  };

  return (
    <Fragment>
      <div onClick={handleClick}>
        <div className={styles.profile_wrapper}>
          <div className={styles.name_wrapper}>
            {/* <div className={styles.name}> 
              {userInfo?.firstName ?? ""}
            </div>
            <div className={styles.id}>{userInfo?.email ?? ""}</div> */}
          </div>
          <div className={styles.image_wrapper}>
            {renderProfileImage(userInfo)}
          </div>
        </div>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={`ProfileDropdown`}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <li className={styles.profile_dropdown_wrapper}>
          <div className={`${styles.profile_wrapper} ${styles.profile_info} `}>
            <div className={styles.image_wrapper}>
              {renderProfileImage(userInfo)}
            </div>
            <div className={styles.name_wrapper}>
              {userInfo &&
                userInfo.userTypeSlug &&
                (userInfo.userTypeSlug == "student" ||
                  userInfo.userTypeSlug == "mentor" ||
                  userInfo.userTypeSlug == "counsellor") && (
                  <Fragment>
                    <div className={styles.name}>
                      {userInfo?.firstName ?? ""}
                    </div>
                    <div className={styles.id}>{userInfo?.email ?? ""}</div>
                  </Fragment>
                )}
            </div>
          </div>
          <div className={styles.divider}></div>
          <ul className={styles.menu_list}>
            {userInfo &&
              userInfo.userTypeSlug &&
              userInfo.userTypeSlug == "student" && (
                <Fragment>
                  <li>
                    <Link legacyBehavior href={`/app/student`}>
                      <a className={styles.link}>
                        <span>My Dashboard</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href={`/app/student/profile`}>
                      <a className={styles.link}>
                        <span>My Profile</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/app/student/settings">
                      <a className={styles.link}>
                        <span>Account Settings</span>
                      </a>
                    </Link>
                  </li>
                </Fragment>
              )}

            <li onClick={logout}>
              <span className={styles.link}>Sign Out</span>
            </li>
          </ul>
        </li>
      </Menu>
    </Fragment>
  );
};
export default AccountMenu;
