import { Dialog } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import { FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import useWindowDimensions from "_hooks/useWindowDimensions";
import styles from "./event.module.scss";

const EventPopupCanada = ({ openEventPopup, closeEventPopup }) => {
  const { isOpen: homePopupIsOpen } = useSelector((state) => state.homePopup);
  const { width } = useWindowDimensions();

  return (
    <>
      {/* {!homePopupIsOpen && ( */}
      <Dialog
        open={openEventPopup}
        onClose={() => closeEventPopup(false)}
        maxWidth={"md"}
        fullWidth={true}
        classes={{ paper: styles.modal_width }}
      >
        <FiX onClick={() => closeEventPopup(false)} className={styles.close} />
        <div className={styles.wrapper}>
          <div className={styles.left}>
            {width > 720 ? (
              <>
                <Image
                  src="/assets/images/events/images/canada-admission-day-popup.webp"
                  alt="Attend UniSearch’s Canada Admission Day"
                  title="Attend UniSearch’s Canada Admission Day"
                  width={270}
                  height={474}
                  className={styles.image}
                />
              </>
            ) : (
              <>
                <Image
                  src="/assets/images/events/images/canada-admission-day-popup-mobile.webp"
                  alt="Attend UniSearch’s Canada Admission Day"
                  title="Attend UniSearch’s Canada Admission Day"
                  width={720}
                  height={162}
                  className={styles.image}
                />
              </>
            )}
          </div>

          <div className={styles.right}>
            <div className={styles.subtitle}>
              Dreaming of Studying in Canada?
            </div>
            <h2 className={styles.title}>
              Attend UniSearch’s Canada Admission Day
            </h2>

            <div className={styles.event_tag}>VIRTUAL EVENT</div>

            <div className={styles.info}>
              <div className={styles.info_item}>
                <Image
                  src="/assets/images/events/icons/calender.webp"
                  alt="Data 4th March, 2023"
                  width={16}
                  height={16}
                />
                <span>25th March, 2023</span>
              </div>
              <div className={styles.info_item}>
                <Image
                  src="/assets/images/events/icons/watch.webp"
                  alt="time 2pm - 5pm"
                  width={16}
                  height={16}
                />
                <span>2 pm - 5 pm</span>
              </div>
              <div className={styles.info_item}>
                <Image
                  src="/assets/images/events/icons/map-pin.webp"
                  alt="Place Chandigarh, India"
                  width={16}
                  height={16}
                />
                <span>Chandigarh, India</span>
              </div>
            </div>

            <ul className={styles.heighlights}>
              <li>
                <ReactSVG src="/assets/images/events/icons/checked.svg" />
                <span>Meet Canada university representatives</span>
              </li>
              <li>
                <ReactSVG src="/assets/images/events/icons/checked.svg" />
                <span>Get on-spot profile evaluation</span>
              </li>
              <li>
                <ReactSVG src="/assets/images/events/icons/checked.svg" />
                <span>Access personalized guidance</span>
              </li>
              <li>
                <ReactSVG src="/assets/images/events/icons/checked.svg" />
                <span>
                  Explore options for the next intake <br />
                  And more!
                </span>
              </li>
            </ul>

            <div className={styles.btn_wrapper}>
              <Link
                href="/events/canada-admissions-day"
                className={`btn btn-secondary btn-bg`}
              >
                Sign Up Today
              </Link>
            </div>
          </div>
        </div>
      </Dialog>
      {/* )} */}
    </>
  );
};

export default EventPopupCanada;
