import AdvancedSearch from "@modules/study/common/advance_search/advanced_search_dropdown";
import { searchPopupState } from "@_recoil/_state/search_popup";
import dynamic from "next/dynamic";
import { Fragment, useState } from "react";
import { FiSearch, FiX } from "react-icons/fi";
import { useRecoilState } from "recoil";
import styles from "./header_search.module.scss";
const Modal = dynamic(() => import("@mui/material/Modal"), { ssr: false });
import { useAutoAnimate } from "@formkit/auto-animate/react";

const CourseSearch = dynamic(
  () => import("@modules/study/common/advance_search/common"),
  { ssr: false }
);
const CommonSearch = dynamic(
  () => import("@modules/study/common/advance_search/content_search"),
  { ssr: false }
);

const HeaderSearch = (props) => {
  const [animationParent] = useAutoAnimate();

  const [_, setPopupState] = useRecoilState(searchPopupState);

  const [tab, setTab] = useState(1);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setPopupState({ open: true, enablePopup: false });
  };
  const handleClose = () => {
    setOpen(false);
    setPopupState((prev) => ({ ...prev, open: false }));
  };

  return (
    <Fragment>
      <div
        onClick={handleOpen}
        className={`${styles.header_search_wrapper} ${props.className}`}
      >
        <div className={styles.search_placeholder}>{props.placeholder}</div>
        <FiSearch className={styles.search_icon} />
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        sx={{ zIndex: "2000" }}
        id="search_modal"
      >
        <div className={styles.search_popup}>
          <button onClick={handleClose} className={styles.cross_icon}>
            <FiX />
          </button>

          <div className="container">
            <div className={styles.content_wrapper}>
              <div className={styles.tab_wrapper}>
                <button
                  className={`btn ${
                    tab === 1
                      ? `btn-secondary ${styles.hover_change}`
                      : "btn-outline"
                  } btn-bg`}
                  onClick={() => setTab(1)}
                >
                  Course Search
                </button>

                <button
                  className={`btn ${
                    tab === 2
                      ? `btn-secondary ${styles.hover_change}`
                      : "btn-outline"
                  }  btn-bg`}
                  onClick={() => setTab(2)}
                >
                  Advanced Course Search
                </button>

                <button
                  className={`btn ${
                    tab === 3
                      ? `btn-secondary ${styles.hover_change}`
                      : "btn-outline"
                  }  btn-bg`}
                  onClick={() => setTab(3)}
                >
                  Common Search
                </button>
              </div>

              <div
                className={styles.search_input_wrapper}
                ref={animationParent}
              >
                {tab === 1 && (
                  <div className="relative">
                    <CourseSearch
                      showBig={props.showBig}
                      popupHandler={handleClose}
                    />
                  </div>
                )}
                {tab === 2 && (
                  <div>
                    <AdvancedSearch popupHandler={handleClose} />
                  </div>
                )}
                {tab === 3 && (
                  <div className={styles.conent_search_wrapper}>
                    <CommonSearch popupHandler={handleClose} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default HeaderSearch;
