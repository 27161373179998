import AdvancedSearchCourseForm from "./components/course_form";

export const AdvancedSearch = () => {
  return (
    <div className="max-w-[700px] w-full mx-auto flex justify-center p-5 md:p-10 mb-10 border rounded-lg">
      <div className="w-full">
        <AdvancedSearchCourseForm />
      </div>
    </div>
  );
};

export default AdvancedSearch;
