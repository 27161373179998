import { authAtom } from "@_recoil/_state";
import Link from "next/link";
import { useEffect, useState } from "react";
import { MdFavoriteBorder } from "react-icons/md";
import { useRecoilValue } from "recoil";
import styles from "./favorite.module.scss";

const FavoriteComponent = () => {
  const userDatas = useRecoilValue(authAtom);
  const [userData, setUserData] = useState();
  const [favrouteState, coutnFavroute] = useState(0);

  useEffect(() => {
    if (userDatas) {
      setUserData(userDatas);
    }
  }, [userDatas]);

  useEffect(() => {
    if (
      userData &&
      (userData.favoriteCourseList || userData.favoriteUniversityList)
    ) {
      let fevNum = 0;

      if (userData.favoriteCourseList && userData.favoriteCourseList.length) {
        fevNum += userData.favoriteCourseList.length ?? 0;
      }
      if (
        userData.favoriteUniversityList &&
        userData.favoriteUniversityList.length
      ) {
        fevNum += userData.favoriteUniversityList.length ?? 0;
      }
      coutnFavroute(fevNum);
    } else {
      coutnFavroute(0);
    }
  }, [userData]);

  return (
    <div className={styles.compare_dropdown_parent}>
      <div className={styles.compare}>
        <MdFavoriteBorder />
        <span className={styles.compare_num}>
          {favrouteState && (favrouteState > 9 ? `9+` : favrouteState)}
        </span>
      </div>
      {favrouteState > 0 && (
        <div className={styles.compare_dropdown}>
          {userData &&
            userData.favoriteUniversityList &&
            userData.favoriteUniversityList.length > 0 && (
              <Link href="/app/student/favorite/university">
                <span className={styles.comp_name}>University</span>
                <span className={styles.comp_count}>
                  {parseInt(userData && userData.favoriteUniversityList.length)}
                </span>
              </Link>
            )}
          {userData &&
            userData.favoriteCourseList &&
            userData.favoriteCourseList.length > 0 && (
              <Link href="/app/student/favorite/courses">
                <span className={styles.comp_name}>Course</span>
                <span className={styles.comp_count}>
                  {parseInt(userData && userData.favoriteCourseList.length)}
                </span>
              </Link>
            )}
        </div>
      )}
    </div>
  );
};

export default FavoriteComponent;
