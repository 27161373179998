import Skeleton from "@unisearch/common/skeleton";
import styles from "./loader.module.scss";

export const HeaderMainLoader = () => {
  return (
    <div className="container-extend">
      <div className={styles.grid}>
        {new Array(3).fill(1).map((_, index) => {
          return <Skeleton className={styles.sk1} key={index} />;
        })}
      </div>
      <div className={styles.grid2}>
        {new Array(3).fill(1).map((_, i) => {
          return <Skeleton key={i} className={styles.grid2_skeletor} />;
        })}
      </div>
    </div>
  );
};

export default HeaderMainLoader;
