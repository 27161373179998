import Link from "next/link";
import React, { Fragment } from "react";
import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import HeaderMobileTop from "./components/header_mobile_top";

export const HeaderMobileOne = (props) => {
  const { menuList, isLoading } = props?.menuData;

  const menuTree = (menuListItem) => {
    return (
      <ProSidebar className="MobileSideMenu">
        <Menu>
          {menuListItem &&
            menuListItem.length > 0 &&
            menuListItem.map((menuItem, index) => {
              return (
                <Fragment key={`menuItem${index}${menuItem.title}`}>
                  {menuItem.children.length > 0 ? (
                    <Fragment>
                      {menuItem.isGroup === 1 ? (
                        <SubMenu title={menuItem.title}>
                          {menuItem.children &&
                            menuItem.children.length > 0 &&
                            menuItem.children.map((groupHead, groupIndex) => {
                              return (
                                <Fragment
                                  key={`menuItemGroup${groupIndex}${groupHead.title}`}
                                >
                                  <MenuItem style={{ fontWeight: 500 }}>
                                    <Link legacyBehavior href={groupHead.link}>
                                      <a>{groupHead.title}</a>
                                    </Link>
                                  </MenuItem>
                                  {groupHead.children &&
                                    groupHead.children.length > 0 &&
                                    groupHead.children.map(
                                      (groupChild, childIndex) => {
                                        return (
                                          <Fragment
                                            key={`menuItemChild${childIndex}${groupChild.title}`}
                                          >
                                            <MenuItem>
                                              <Link
                                                legacyBehavior
                                                href={groupChild.link}
                                              >
                                                <a>{groupChild.title}</a>
                                              </Link>
                                            </MenuItem>
                                          </Fragment>
                                        );
                                      }
                                    )}
                                </Fragment>
                              );
                            })}
                        </SubMenu>
                      ) : (
                        <SubMenu title={menuItem.title}>
                          {menuItem.children &&
                            menuItem.children.length > 0 &&
                            menuTree(menuItem.children)}
                        </SubMenu>
                      )}
                    </Fragment>
                  ) : (
                    <MenuItem>
                      <Link legacyBehavior href={menuItem.link}>
                        <a>{menuItem.title}</a>
                      </Link>
                    </MenuItem>
                  )}
                </Fragment>
              );
            })}
        </Menu>
      </ProSidebar>
    );
  };
  return (
    <>
      <HeaderMobileTop setOpen={props.setOpen} />
      {menuTree(menuList)}
    </>
  );
};

export default HeaderMobileOne;
