import Skeleton from "@mui/material/Skeleton";
import LiveSearch from "@unisearch/common/live_search/index.jsx";
import API from "@unisearch/helpers/api";
import { useEffect, useState } from "react";
import styles from "./course_form.module.scss";
import CourseFormSelectLevel from "./select_level.jsx";
import CourseFormSelectCountry from "./select_country";

const AdvancedSearchCourseForm = () => {
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [levelValue, setLevelValue] = useState(null);
  const [countryValue, setCountryValue] = useState(null);

  const [result, setResult] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await API.get(
          `public/specialization/paginate?filter=${input}`
        );
        if (res.data) {
          setResult(res.data.results);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    if (input && input?.length > 0) {
      fetchData();
    }
  }, [input]);

  const pushToSearchPage = () => {
    if (typeof input === "object") {
      window.location.replace(
        `${process.env.NEXT_PUBLIC_URL}/search/${input?.slug}`
      );
    }
    if (typeof input === "object" && levelValue) {
      window.location.replace(
        `${process.env.NEXT_PUBLIC_URL}/search/${input?.slug}/${levelValue?.value}`
      );
    }
    if (typeof input === "object" && levelValue && countryValue) {
      window.location.replace(
        `${process.env.NEXT_PUBLIC_URL}/search/${input?.slug}/${levelValue?.value}/${countryValue?.value}`
      );
    }
  };

  return (
    <div className="grid gap-6">
      <div className={`${styles.form_wrapper}`}>
        <LiveSearch
          results={result}
          input={input}
          setResult={setResult}
          value={selectedValue?.specializationName}
          placeholder="Course Subject e.g Computer Science"
          renderItem={(item) => {
            return (
              <>
                {loading ? (
                  <Skeleton />
                ) : (
                  <div className={styles.dropdown_item}>
                    <div className={styles.dropdown_head}>
                      {item?.specializationName}
                    </div>
                  </div>
                )}
              </>
            );
          }}
          onChange={handleChange}
          onSelect={(item) => {
            setSelectedValue(item);
            setInput(item);
          }}
          customClasses={{ root: styles.root }}
        />
      </div>

      <div className="border-[10px] border-white rounded-[10px]">
        <CourseFormSelectLevel
          levelValue={levelValue}
          setLevelValue={setLevelValue}
        />
      </div>

      {levelValue && (
        <div className="border-[10px] border-white rounded-[10px]">
          <CourseFormSelectCountry
            countryValue={countryValue}
            setCountryValue={setCountryValue}
          />
        </div>
      )}
      <div className="flex justify-end">
        <button
          onClick={pushToSearchPage}
          disabled={typeof input !== "object"}
          className={`btn btn-bg btn-primary ${
            typeof input !== "object" ? "bg-[#7a7a7a] pointer-events-none	" : ""
          }`}
        >
          Find Courses
        </button>
      </div>
    </div>
  );
};

export default AdvancedSearchCourseForm;
