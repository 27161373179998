import { LeadForm } from "@modules/lead/components";
import Modal from "@mui/material/Modal";
import API from "@unisearch/helpers/api";
import Image from "next/legacy/image";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import styles from "./lead.module.scss";

const LeadPopupNew = ({ openLeadPopup, setOpenLeadPopup }) => {
  const [pageData, setPageData] = useState();

  const fetchData = async () => {
    const res = await API.get(`public/lead-contact-form/pages/popup`);
    if (res && !res.error) {
      setPageData(res.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Modal
        open={openLeadPopup}
        onClose={() => setOpenLeadPopup(false)}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        id="popup_modal"
      >
        <div className="modal md">
          <button
            className="cross_icon"
            onClick={() => setOpenLeadPopup(false)}
            style={{ transform: "unset", top: "15px", right: "8px" }}
          >
            <FiX />
          </button>

          <div className={styles.popup_wrapper}>
            <div className={styles.popup_left}>
              <h3 className={styles.heading}>
                Want to study abroad? Sign up with{" "}
                <span className={"color-orange"}>UniSearch!</span>
              </h3>
              <p className={styles.sub_heading}>
                Jumpstart your study abroad dreams with UniSearch
              </p>

              <ul className={styles.list_wrapper}>
                <li>
                  <Image
                    src="/assets/icons/popup_check.png"
                    width={12}
                    height={12}
                  />
                  <span>Free counselling for major destinations</span>
                </li>
                <li>
                  <Image
                    src="/assets/icons/popup_check.png"
                    width={12}
                    height={12}
                  />
                  <span>Thorough application and visa guidance</span>
                </li>
                <li>
                  <Image
                    src="/assets/icons/popup_check.png"
                    width={12}
                    height={12}
                  />
                  <span>Discover scholarships and funding</span>
                </li>
                <li>
                  <Image
                    src="/assets/icons/popup_check.png"
                    width={12}
                    height={12}
                  />
                  <span>Complete IELTS and PTE Academic support</span>
                </li>
              </ul>
            </div>
            <div className={styles.popup_right}>
              <p className={styles.right_heading}>
                Register today to discover, connect with, and apply to your
                ideal university abroad!
              </p>
              <div className={styles.popup_form_wrapper}>
                <LeadForm
                  showTitle={false}
                  showSubTitle={false}
                  pageData={pageData}
                  buttonTitle="Submit"
                  btnSizeFull={false}
                  handleClose={() => setOpenLeadPopup(false)}
                  pageSlug={pageData?.slug}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LeadPopupNew;
