/* eslint-disable @next/next/next-script-for-ga */
/* eslint-disable @next/next/no-img-element */
import React, { Fragment, useEffect, useState } from "react";
import Script from "next/script";
import Head from "next/head";
import { getAssets } from "@unisearch/helpers";
import dynamic from "next/dynamic";
import { authAtom } from "@_recoil/_state";
import { useRecoilValue } from "recoil";
import { useGetNavQuery } from "@redux/misc/miscApi/navApi";
import HeaderMainLoader from "./headers/header_one/loader";
import Popup from "@unisearch/common/popup";
import CookiePopup from "@unisearch/common/cookie_popup";
import Cookies from "js-cookie";

const HeaderOne = dynamic(
  () => import("@unisearch/theme/layout/headers/header_one"),
  {
    ssr: false,
    loading: () => <HeaderMainLoader />,
  }
);

const HeaderCampaign = dynamic(
  () => import("@unisearch/theme/layout/headers/header_campaign"),
  {
    ssr: false,
  }
);

const FixedPopup = dynamic(() => import("./components/fixed_popup"), {
  ssr: false,
});

const FooterOne = dynamic(
  () => import("@unisearch/theme/layout/footers/footer_one"),
  {
    ssr: false,
  }
);

export const LayoutMain = ({
  footer_with_evaluation = false,
  children,
  title,
  metaTitle,
  metaKeywords,
  metaDescription,
  metaUrl,
  metaImage,
  header = "headerOne",
  fixedPopup = true,
  footer = true,
  JsonLd,
  isHeaderSticky = false,
  isLeadPopup = false,
  isCookiePopup = true,
  ogType = "website",
}) => {
  const userDatas = useRecoilValue(authAtom);
  const [userData, setUserData] = useState();
  const { data, isLoading } = useGetNavQuery();
  const menuList = data?.data;

  const cookie_policy = Cookies.get("cookie_policy");
  const [cookieView, setCookiePolicy] = useState(false);

  const formatedTitle = title
    ? `${title} | UniSearch`
    : "UniSearch: The Ultimate Study Abroad Toolkit for Every Stage | UniSearch";

  const formatedMetaTitle = metaTitle
    ? `${metaTitle} | UniSearch`
    : formatedTitle;

  const formatedMetaDescriptionRaw = metaDescription
    ? `${metaDescription}`
    : formatedTitle.replace("| UniSearch", "");
  const formatedMetaDescription = formatedMetaDescriptionRaw?.slice(0, 150);

  useEffect(() => {
    if (userDatas) {
      setUserData(userDatas);
    }
    if (cookie_policy && cookie_policy == "Yes") {
      setCookiePolicy(false);
    } else {
      setCookiePolicy(true);
    }
  }, [userDatas]);

  const handleCookiePopup = () => {
    Cookies.set("cookie_policy", "Yes");
    setCookiePolicy(false);
  };

  return (
    <Fragment>
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#f47f20" />
        <title>{formatedTitle}</title>
        <link rel="manifest" href="/manifest.webmanifest" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />

        <meta
          name="p:domain_verify"
          content="c7f4dc207b5a2b8446692325a0dfb112"
        />
        <meta
          name="facebook-domain-verification"
          content="025mfzyubxk5w3ws6cj9ra838kz8sz"
        />
        {process.env.NEXT_PUBLIC_URL &&
          process.env.NEXT_PUBLIC_URL == "https://myunisearch.com" && (
            <meta
              name="google-site-verification"
              content="RFoBnS3QiQEsqCjHmul_v48eD6tcRodn-Sk4enU8UI8"
            />
          )}
        {process.env.NEXT_PUBLIC_URL &&
          process.env.NEXT_PUBLIC_URL == "https://dev.myunisearch.com" && (
            <>
              <meta name="robots" content="noindex" />
            </>
          )}

        {metaUrl && <link rel="canonical" href={metaUrl} />}
        <meta name="title" content={formatedMetaTitle} />
        <meta
          name="keywords"
          content={
            metaKeywords || "unisearch, study abroad, exam, document, courses"
          }
        />
        <meta name="description" content={formatedMetaDescription} />

        {/* Opengraph */}
        <meta property="og:title" content={formatedMetaTitle} />
        <meta property="og:description" content={formatedMetaDescription} />

        <meta property="og:type" content={ogType} />
        <meta property="og:site_name" content="UniSearch" />
        <meta
          property="og:url"
          content={metaUrl ?? "https://myunisearch.com/"}
        />
        <meta
          property="og:image"
          content={metaImage ?? getAssets("assets/unisearch-meta-logo.png")}
        />
        <link
          rel="apple-touch-icon"
          href={metaImage ?? getAssets("assets/unisearch-meta-logo.png")}
        />

        {/* Twitter */}
        <meta name="twitter:title" content={formatedMetaTitle} />
        <meta name="twitter:description" content={formatedMetaDescription} />
        <meta
          name="twitter:image"
          content={metaImage || getAssets("assets/unisearch-meta-logo.png")}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="referrer" content="no-referrer"></meta>

        {JsonLd && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={JsonLd()}
          />
        )}
      </Head>

      {/* Google Analytics */}
      {/* Script strategy= lazyOnload | beforeInteractive | afterInteractive */}
      <Script
        defer
        data-domain={
          process.env.NEXT_PUBLIC_DOMAIN
            ? process.env.NEXT_PUBLIC_DOMAIN
            : "myunisearch.com"
        }
        src="https://analytics.myunisearch.com/js/plausible.js"
        strategy="lazyOnload"
      />
      <Script
        async
        crossorigin="anonymous"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1690314786863972"
        strategy="lazyOnload"
      />

      <Script
        defer
        src="https://www.googletagmanager.com/gtag/js?id=G-CBM37S40N3"
        strategy="lazyOnload"
      />

      <Script
        defer
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-CBM37S40N3', { page_path: window.location.pathname });
            `,
        }}
        strategy="lazyOnload"
      />

      {/* Facebook Metas */}
      <Script
        defer
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '466355328286746');
            fbq('track', 'PageView');
            `,
        }}
        strategy="lazyOnload"
      />
      <noscript>
        <img
          height="0"
          width="0"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=466355328286746&ev=PageView&noscript=1"
          alt=""
        />
      </noscript>
      {/* Facebook Metas end*/}

      {/* chat bot start*/}
      <Script
        // defer
        src="https://unpkg.com/typebot-js@2.2"
        // strategy="lazyOnload"
      />
      <Script
        defer
        dangerouslySetInnerHTML={{
          __html: ` 
        if (typeof Typebot === 'object') {
          var typebotCommands = Typebot.initBubble({
            url: "https://unibot-view.myunisearch.com/unisearch-query-chat-u6kgek5",
            proactiveMessage: {
              avatarUrl: "https://img.icons8.com/office/512/robot-2.png",
              textContent: "I have a question for you!",
              delay: 0,
            },
            button: { color: "#d48311" },
          });
        }
          `,
        }}
        strategy="lazyOnload"
      />
      {/* chat bot end*/}

      {/* bug report start */}
      <Script defer src="//d2wy8f7a9ursnm.cloudfront.net/v7/bugsnag.min.js" />
      {/* <script>  Bugsnag.start({ apiKey: 'f816a469163b124a51e8851b652ecba5' })</script> */}
      <Script strategy="lazyOnload">
        {`
        if (typeof Bugsnag === 'object') {
        Bugsnag.start({ apiKey: 'f816a469163b124a51e8851b652ecba5' })}`}
      </Script>
      {/* bug report end */}

      <>
        {header === "headerOne" && (
          <HeaderOne
            isHeaderSticky={isHeaderSticky}
            menuData={{ menuList, isLoading }}
            userData={userData}
          />
        )}
        {header === "headerCampaign" && <HeaderCampaign />}
      </>
      <main>{children}</main>

      <Popup isLeadPopup={isLeadPopup} />

      {/* {cookieView && cookieView == true ? (
        <CookiePopup handleCookiePopup={handleCookiePopup} />
      ) : null} */}

      {fixedPopup && <FixedPopup />}
      {footer && (
        <FooterOne
          userData={userData}
          footer_with_evaluation={footer_with_evaluation}
        />
      )}
    </Fragment>
  );
};
export default LayoutMain;
