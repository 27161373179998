/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { BiBookReader } from "react-icons/bi";
import { BsPersonSquare } from "react-icons/bs";
import { CgArrowDownR, CgProfile } from "react-icons/cg";
import { FiFileText } from "react-icons/fi";
import { FaComments } from "react-icons/fa";
import API from "@unisearch/helpers/api";
import { HiOutlineClipboardList } from "react-icons/hi";
import {
  MdDateRange,
  MdEmail,
  MdFavoriteBorder,
  MdMenuBook,
  MdOutlineAssignmentInd,
  MdOutlineGridView,
  MdOutlineQuestionAnswer,
  MdOutlineReviews,
} from "react-icons/md";

import styles from "./sidecontent_student.module.scss";
import { getAssets } from "@unisearch/helpers";
import { IoMdCart } from "react-icons/io";
import { RiFileList2Line } from "react-icons/ri";

const SidecontentStudent = ({ profileInfo }) => {
  const { asPath, query } = useRouter();

  const generatePath = () => {
    const pathArray = asPath.split("/");
    pathArray.shift();
    return pathArray[2];
  };

  const RenderNavCard = (pathName) => {
    switch (pathName) {
      case "elearning":
        return ElearningNav();
      default:
        return StudentNav();
    }
  };

  const StudentNav = () => {
    return (
      <ul>
        <li>
          <Link legacyBehavior href="/app/student">
            <a
              className={`${
                asPath === `/app/student` ? `${styles.active}` : ""
              }`}
            >
              <MdOutlineGridView className={styles.dashboard_icon} />
              <span>Dashboard</span>
            </a>
          </Link>
        </li>
        <li>
          <Link legacyBehavior href="/app/student/profile">
            <a
              className={`${
                asPath.includes(`/app/student/profile`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <CgProfile className={styles.dashboard_icon} />
              <span>Profile</span>
            </a>
          </Link>
        </li>
        <li>
          <Link legacyBehavior href="/app/student/conversation/inbox">
            <a
              className={`${
                asPath.includes(`/app/student/conversation`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <MdEmail />
              <span>Conversations</span>
            </a>
          </Link>
        </li>
        <li>
          <Link legacyBehavior href="/app/student/messages">
            <a
              className={`${
                asPath.includes(`/app/student/messages`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <MdOutlineQuestionAnswer />
              <span>Chat</span>
            </a>
          </Link>
        </li>
        <li>
          <Link legacyBehavior href="/app/student/offered-courses">
            <a
              className={`${
                asPath.includes(`/app/student/offered-courses`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <RiFileList2Line />
              <span>Recommended Courses</span>
            </a>
          </Link>
        </li>
        <li>
          <Link legacyBehavior href="/app/student/report">
            <a
              className={`${
                asPath.includes(`/app/student/report`) ? `${styles.active}` : ""
              }`}
            >
              <FiFileText />
              <span>Report</span>
            </a>
          </Link>
        </li>

        <li>
          <Link legacyBehavior href="/app/student/application">
            <a
              className={`${
                asPath.includes(`/app/student/application`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <HiOutlineClipboardList />
              <span>Application</span>
            </a>
          </Link>
        </li>

        <li>
          <Link legacyBehavior href="/app/student/counsellor">
            <a
              className={`${
                asPath.includes(`/app/student/counsellor`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <BsPersonSquare style={{ width: "20px" }} />
              <span>Counsellors</span>
            </a>
          </Link>
        </li>
        {/* <li>
          <Link legacyBehavior href="/app/student/mentor">
            <a
              className={`${
                asPath.includes(`/app/student/mentor`) ? `${styles.active}` : ""
              }`}
            >
              <MdOutlineAssignmentInd />
              <span>Mentors</span>
            </a>
          </Link>
        </li> */}
        <li>
          <Link legacyBehavior href="/app/student/favorite">
            <a
              className={`${
                asPath.includes(`/app/student/favorite`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <MdFavoriteBorder />
              <span>Favourites</span>
            </a>
          </Link>
        </li>
        <li>
          <Link legacyBehavior href="/app/student/reading-list">
            <a
              className={`${
                asPath.includes(`/app/student/reading-list`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <BiBookReader />
              <span>Reading List</span>
            </a>
          </Link>
        </li>
        <li>
          <Link legacyBehavior href="/app/student/reviews">
            <a
              className={`${
                asPath.includes(`/app/student/reviews`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <MdOutlineReviews />
              <span>Reviews</span>
            </a>
          </Link>
        </li>
        <li>
          <Link legacyBehavior href="/app/student/comments">
            <a
              className={`${
                asPath.includes(`/app/student/comments`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <FaComments />
              <span>Comments</span>
            </a>
          </Link>
        </li>
        {/* <li>
          <Link legacyBehavior href="/app/student/appointment/mentor">
            <a
              className={`${
                asPath.includes(`/app/student/appointment`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <MdDateRange />
              <span>Appointment</span>
            </a>
          </Link>
        </li> */}

        {process.env.NEXT_PUBLIC_COMPONENT_ISHIDDEN !== "true" ? (
          <>
            <li>
              <Link legacyBehavior href="/app/student/report">
                <a
                  className={`${
                    asPath.includes(`/app/student/report`)
                      ? `${styles.active}`
                      : ""
                  }`}
                >
                  <FiFileText />
                  <span>Report</span>
                </a>
              </Link>
            </li>

            {/* <li>
              <Link legacyBehavior href="/app/student/download">
                <a
                  className={`${
                    asPath.includes(`/app/student/download`)
                      ? `${styles.active}`
                      : ""
                  }`}
                >
                  <CgArrowDownR />
                  <span>Downloads</span>
                </a>
              </Link>
            </li> */}

            {/* <li>
              <Link legacyBehavior href="/app/student/saved-searches">
                <a
                  className={`${
                    asPath.includes(`/app/student/saved-searches`)
                      ? `${styles.active}`
                      : ""
                  }`}
                >
                  <AiOutlineFileSearch />
                  <span>Saved Searches</span>
                </a>
              </Link>
            </li> */}
          </>
        ) : (
          ""
        )}
      </ul>
    );
  };

  const ElearningNav = () => {
    return (
      <ul>
        <li>
          <Link legacyBehavior href="/app/student/elearning">
            <a
              className={`${
                asPath === `/app/student/elearning` ? `${styles.active}` : ""
              }`}
            >
              <MdOutlineGridView className={styles.dashboard_icon} />
              <span>Dashboard</span>
            </a>
          </Link>
        </li>

        <li>
          <Link legacyBehavior href="/app/student/elearning/courses">
            <a
              className={`${
                asPath.includes(`/app/student/elearning/courses`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <MdMenuBook />
              <span>Courses</span>
            </a>
          </Link>
        </li>

        {/* <li>
          <Link legacyBehavior href="/app/student/elearning/history">
            <a
              className={`${
                asPath.includes(`/app/student/elearning/history`)
                  ? `${styles.active}`
                  : ""
              }`}
            >
              <IoMdCart />
              <span>History</span>
            </a>
          </Link>
        </li> */}
      </ul>
    );
  };

  return (
    <div className={styles.side_wrapper}>
      {/* user */}
      <div className={styles.user_wrapper}>
        <div className={styles.avatar}>
          <img
            src={
              profileInfo?.profileImageSrc ||
              getAssets("assets/student-avatar-small.png")
            }
            alt={`${profileInfo?.firstName} ${profileInfo?.middleName} ${profileInfo?.lastName}`}
          />
        </div>
        <h3 className={styles.name}>
          Hello {profileInfo?.firstName} {profileInfo?.middleName}{" "}
          {profileInfo?.lastName}
        </h3>
      </div>

      {/* pages link */}
      <div className={styles.link_wrapper}>{RenderNavCard(generatePath())}</div>
    </div>
  );
};

export default SidecontentStudent;
