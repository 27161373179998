import React from "react";
import { LeadForm } from "..";
import styles from "./form.module.scss";

export const LeadSectionForm = ({pageData}) => {
  return (
    <section id="form" className={styles.form_section}>
      <div className="container">
        <div className={styles.form_wrapper}>
          <LeadForm pageData={pageData}/>
        </div>
      </div>
    </section>
  );
};

export default LeadSectionForm;
