import { Grid } from "@mui/material";
import { hexToRGB } from "@unisearch/helpers";
import { Fragment } from "react";
import styles from "./highlights.module.scss";

export const LeadSectionHighlights = ({ pageData }) => {
  return (
    <Fragment>
      {pageData?.pageContent?.featuresSection?.length > 0 ? (
        <Fragment>
          {pageData?.pageContent?.featuresSection?.map((secData, i) => {
            return (
              <section className={styles.section_onlinetest} key={i}>
                <div className="container">
                  {secData?.featureTitle && (
                    <h2 className={styles.title}>{secData?.featureTitle}</h2>
                  )}
                  {secData?.featuresCard?.length > 0 && (
                    <Grid
                      container
                      spacing={{ xs: 3, sm: 3, md: 4 }}
                      justifyContent="center"
                    >
                      {secData.featuresCard?.map((item, i) => {
                        const bgColor = hexToRGB(item?.cardColor);

                        let showCard = 3;
                        if (secData?.featuresCard?.length <= 3) {
                          showCard = 4;
                        }

                        return (
                          <Grid item xs={12} sm={6} md={showCard} key={i}>
                            <div className={`${styles.test_card}`}>
                              <div
                                className={styles.test_card_circle_1}
                                style={{
                                  backgroundColor: `${item?.cardColor}`,
                                }}
                              ></div>
                              <div
                                className={styles.test_card_circle_2}
                                style={{
                                  backgroundColor: `rgba(${bgColor}, .8)`,
                                }}
                              ></div>
                              {item?.cardIconSrc && (
                                <div className={styles.test_icon}>
                                  <img src={item?.cardIconSrc} />
                                </div>
                              )}
                              {item?.cardTitle && (
                                <h4 className={styles.test_title}>
                                  {item?.cardTitle}
                                </h4>
                              )}
                              {item?.cardDescription && (
                                <div className={styles.test_desc}>
                                  {item?.cardDescription}
                                </div>
                              )}
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </div>
              </section>
            );
          })}
        </Fragment>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default LeadSectionHighlights;
