import { apiSlice } from "../../apiSlice";

export const navApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getNav: build.query({
      query: () => `/public/navbar`,
    }),
  }),
});

export const { useGetNavQuery } = navApi;
