import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { searchPopupState } from "@_recoil/_state/search_popup";
import { useRecoilValue } from "recoil";
import EventPopup from "./event";
import LeadPopupNew from "./lead";
import EventPopupCanada from "./event_canada";

const Popup = ({ isLeadPopup = false }) => {
  const { open, isOpenHomePagePopup } = useRecoilValue(searchPopupState);
  const router = useRouter();
  const { asPath } = router;
  const [openEventPopup, setOpenEventPopup] = useState(false);
  const [openLeadPopup, setOpenLeadPopup] = useState(false);
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpenLeadPopup(true);
    }, 6000);
  }, [asPath]);

  //**
  // Old code start
  //
  // useEffect(() => {
  //   setTimeout(() => {
  //     setOpenEventPopup(true);
  //   }, 2000);
  // }, [asPath]);

  // useEffect(() => {
  //   if (isMounted == false) return;

  //   if (openEventPopup == false) {
  //     setTimeout(() => {
  //       setOpenLeadPopup(true);
  //     }, 2000);
  //   }
  // }, [openEventPopup]);

  // const closeEventPopup = () => {
  //   setOpenEventPopup(false);
  //   setMounted(true);
  // };

  // const closeLeadPopup = () => {
  //   setOpenLeadPopup(false);
  //   setMounted(false);
  // };
  // old code end

  return (
    <>
      {open == false && isOpenHomePagePopup == false ? (
        <>
          {/* <EventPopupCanada
            openEventPopup={openEventPopup}
            closeEventPopup={closeEventPopup}
          />  */}

          {isLeadPopup && (
            <LeadPopupNew
              openLeadPopup={openLeadPopup}
              setOpenLeadPopup={setOpenLeadPopup}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default Popup;
