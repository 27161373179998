import { FullPageLoader } from "@unisearch/common/widgets/loader";
import DashboardTopmenu from "@unisearch/theme/layout/headers/topmenu";
import { useAuthActions } from "@_recoil/_actions";
import { authAtom } from "@_recoil/_state";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from "react-icons/hi";
import { MdOutlineComputer, MdOutlineSchool } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { useRecoilValue } from "recoil";
import styles from "./layout.module.scss";
import SidecontentStudent from "./sidecontent/sidecontent_student";

export const LayoutStudent = ({
  children,
  title,
  pageTitle = false,
  ...props
}) => {
  const { asPath, query } = useRouter();
  const [openSideMenu, setOpenSideMenu] = useState(true);

  const toggleSideMenu = () => {
    setOpenSideMenu((prev) => !prev);
  };
  const [pageLaoder, setPageLoader] = useState(true);
  const isDesktop = useMediaQuery({
    query: "(min-width: 991px)",
  });

  //requil auth action
  const authActions = useAuthActions();
  const profileInfo = useRecoilValue(authAtom);

  useEffect(() => {
    //check profile info
    if (!profileInfo || profileInfo.userTypeSlug != "student") {
      authActions.logout("/signin");
      return false;
    }

    if (profileInfo || profileInfo.userTypeSlug == "student") {
      //remove loader
      setTimeout(() => {
        setPageLoader(false);
      }, 1500);
    }
  }, []);

  const pathArray = asPath.split("/");
  pathArray.shift();

  //generate active nav
  const isElearning = () => {
    if (pathArray.length >= 2 && pathArray[2] == "elearning") {
      return true;
    } else {
      return false;
    }
  };

  const isStudent = () => {
    if (
      (pathArray.length >= 2 && pathArray[2] != "elearning") ||
      pathArray.length == 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <Head>
        <title>{title}</title>
      </Head>
      {pageLaoder && <FullPageLoader />}

      {!pageLaoder && (
        <div className={styles.content}>
          <DashboardTopmenu />

          <div onClick={toggleSideMenu} className={styles.arrow_icon_wrapper}>
            {!openSideMenu ? (
              <HiOutlineChevronDoubleRight />
            ) : (
              <HiOutlineChevronDoubleLeft />
            )}
          </div>

          <div
            className={`${styles.sidemenu} ${
              !openSideMenu ? styles.close_side_menu : ""
            } `}
          >
            <ul>
              <li>
                <Link legacyBehavior href="/app/student">
                  <a
                    className={`${styles.link} ${
                      isStudent() ? styles.active : ""
                    }`}
                  >
                    <MdOutlineSchool />
                  </a>
                </Link>
              </li>
              <li>
                <Link legacyBehavior href="/app/student/elearning">
                  <a
                    className={`${styles.link} ${
                      isElearning() ? styles.active : ""
                    }`}
                  >
                    <MdOutlineComputer />
                  </a>
                </Link>
              </li>
            </ul>
          </div>

          {/* page content with side content */}
          <div
            className={`${styles.page_content} ${
              !openSideMenu ? styles.margin_zero : ""
            }`}
          >
            {isDesktop && (
              <div className={styles.side_content}>
                <SidecontentStudent profileInfo={profileInfo} />
              </div>
            )}
            <div className={styles.main_content}>
              <div className={styles.main_wrapper}>
                <>
                  {asPath !== `/app/student/appointment/meet/${query.id}` &&
                    pageTitle && <h2 className={styles.title}>{pageTitle}</h2>}
                </>
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default LayoutStudent;
