import { Grid } from "@mui/material";
import { Fragment } from "react";
import styles from "./counselling_features.module.scss";

export const LeadSectionCounsellingFeatures = ({ pageData }) => {
  return (
    <Fragment>
      {pageData?.pageContent?.featuresSection?.length > 0 ? (
        <Fragment>
          {pageData?.pageContent?.featuresSection?.map((secData, i) => {
            return (
              <section key={i}>
                <div className="container">
                  <div className={styles.heading_wrapper}>
                    {secData?.featureTitle && (
                      <h2 className={styles.title}>{secData?.featureTitle}</h2>
                    )}
                    {secData?.featureSubtitle && (
                      <p className={styles.sub_text}>
                        {secData?.featureSubtitle}
                      </p>
                    )}
                  </div>
                  {secData?.featuresCard?.length > 0 && (
                    <div className={styles.steps_card_wrapper}>
                      <Grid
                        container
                        spacing={{ xs: 2, sm: 2, md: 3 }}
                        justifyContent="center"
                      >
                        {secData?.featuresCard?.map((item, i) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              key={`featureCard${i}`}
                            >
                              <div className={styles.step_card}>
                                {item?.cardIconSrc && (
                                  <div className={styles.step_card_icon}>
                                    <img
                                      src={item?.cardIconSrc}
                                      alt="Featured icon"
                                    />
                                  </div>
                                )}
                                <p className={styles.step_desc}>
                                  {item?.cardDescription}
                                </p>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  )}
                </div>
              </section>
            );
          })}
        </Fragment>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default LeadSectionCounsellingFeatures;
