export const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#f7f7fe",
    fontSize: "15px",
    padding: "5px",
    border: "0px solid transparent !important",
    borderRadius: "5px",
    boxShadow: "0 !important",
    "&:hover": {
      border: "0px solid transparent !important",
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = "#333";
    return {
      ...styles,
      fontSize: "13px",
      // maxHeight: "150px",
      color: isSelected ? "#F47F20" : "#B5B5C3",
      backgroundColor: isSelected
        ? "#F5F6FC"
        : isFocused
        ? "#F5F6FC"
        : styles.backgroundColor,
      "&:hover": {
        backgroundColor: "#F5F6FC",
        color: "#F47F20",
      },
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    overflow: "auto",
    marginTop: "10px",
    width: "103%",
    border: 0,
    marginLeft: "-8px",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#9ca3af;",
      fontSize: "14px",
    };
  },
};
