import { getAssets } from "@unisearch/helpers";
import dynamic from "next/dynamic";
import Image from "next/legacy/image";
import { useState } from "react";
import { FiX } from "react-icons/fi";
import styles from "./fixed_popup.module.scss";

const Modal = dynamic(() => import("@mui/material/Modal"), {
  ssr: false,
});
const VirtualOfficePopup = dynamic(
  () => import("@modules/study/common/virtual_office_popup"),
  {
    ssr: false,
  }
);

export const FixedPopup = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div onClick={handleOpen} className={styles.fixed_popup_container}>
        <Image
          layout="responsive"
          width={94}
          height={94}
          placeholder="blur"
          blurDataURL={getAssets("assets/Virtual-Office.webp")}
          src={getAssets("assets/Virtual-Office.webp")}
          alt="Virtual-Office"
        />
      </div>

      {/* Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <div className="modal sm">
          <button
            type="button"
            style={{
              position: "absolute",
              top: "24px",
              right: "10px",
            }}
            className="cross_icon"
            onClick={handleClose}
          >
            <FiX />
          </button>
          <VirtualOfficePopup />
        </div>
      </Modal>
    </>
  );
};

export default FixedPopup;
