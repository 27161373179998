import Skeleton from "@unisearch/common/skeleton";
import { getAssets } from "@unisearch/helpers";
import dynamic from "next/dynamic";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FiMail, FiPhone } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { MdPerson } from "react-icons/md";
import useWindowDimensions from "_hooks/useWindowDimensions";

import styles from "./header_one.module.scss";
import Cookies from "js-cookie";

const CompareComponent = dynamic(() => import("../components/compare"), {
  ssr: false,
});
const FavoriteComponent = dynamic(() => import("../components/favorite"), {
  ssr: false,
});
const AccountMenu = dynamic(
  () => import("@unisearch/theme/layout/headers/components/account_menu"),
  { ssr: false }
);
const Tooltip = dynamic(() => import("@mui/material/Tooltip"), {
  ssr: false,
});
const SwipeableDrawer = dynamic(() => import("@mui/material/SwipeableDrawer"), {
  ssr: false,
});
const HeaderMobileOne = dynamic(() => import("../header_mobile"), {
  ssr: false,
});
const HeaderSearch = dynamic(
  () => import("@unisearch/theme/layout/headers/header_search"),
  {
    ssr: false,
  }
);
const SocialLinks = dynamic(
  () => import("@modules/study/common/social_links"),
  {
    ssr: false,
  }
);

export const HeaderOne = ({ menuData, userData, isHeaderSticky }) => {
  const { menuList, isLoading } = menuData;
  const { asPath } = useRouter();
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const [defaultCountryCode, setDefaultCountryCode] = useState(
    Cookies.get("default_country") ?? "au"
  );
  useEffect(() => {
    setTimeout(() => {
      if (typeof Cookies == "object") {
        if (Cookies.get("default_country")) {
          setDefaultCountryCode(Cookies.get("default_country"));
        }
      }
    }, 3000);
  }, [defaultCountryCode]);

  const contactInfo = {
    bd: "+880 1673090958",
    au: "+61 450637122",
    // in: "+61 450637122",
  };

  return (
    <Fragment>
      <header
        id="sticky"
        className="sticky z-[1100] top-0"
        // style={{ top: isHeaderSticky ? "0" : null }}
      >
        <div className={styles.topHeader}>
          <div className="container-extend">
            <div className={styles.top_row}>
              <div className={styles.left_cel}>
                <ul className={styles.uni_info}>
                  <li className={styles.top_phone_num}>
                    <a
                      href={`tel:${contactInfo[defaultCountryCode] ??
                        "+61 450637122"}`}
                    >
                      <FiPhone
                        style={{
                          fontSize: "16px",
                          strokeWidth: 1,
                          color: "#f47f20",
                        }}
                      />
                      <span>
                        {contactInfo[defaultCountryCode] ?? "+61 450637122"}
                      </span>
                    </a>
                  </li>
                  <li className={styles.top_email_address}>
                    <a href="mailto:info@myunisearch.com">
                      <FiMail
                        style={{
                          fontSize: "18px",
                          strokeWidth: 1,
                          color: "#f47f20",
                        }}
                      />
                      <span>info@myunisearch.com</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div className={styles.top_nav}>
                <ul>
                  <li
                    className={
                      !asPath.startsWith("/elearning") &&
                      !asPath.startsWith("/accommodation") &&
                      !asPath.startsWith("/") &&
                      !asPath.startsWith("/jobs")
                        ? styles.active
                        : ""
                    }
                  >
                    <Link href="/">Study</Link>
                  </li>
                  <li
                    className={
                      asPath.startsWith("/elearning") ? styles.active : ""
                    }
                  >
                    <Link href="/elearning">eLearning</Link>
                  </li>
                  <li
                    className={
                      asPath.startsWith("/accommodation") ? styles.active : ""
                    }
                  >
                    <a href="/accommodation">Accommodation</a>
                  </li>
                  <Tooltip title="Coming Soon" arrow>
                    <li>
                      <Link href="#">Internships</Link>
                    </li>
                  </Tooltip>
                </ul>
              </div>

              {width > 1140 && (
                <div className={styles.right_cel}>
                  <SocialLinks />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.mainHeader}>
          <div className="container-extend">
            <div className={styles.row}>
              <AiOutlineMenu
                className={styles.menu_icon}
                onClick={() => setOpen(true)}
              />
              <div className={styles.company_logo}>
                <Link
                  href="/"
                  className={styles.logo}
                  style={{ display: "inline-block" }}
                >
                  <Image
                    src={getAssets("assets/logo-beta.webp")}
                    alt="UniSearch Logo"
                    blurDataURL={getAssets("assets/logo-beta.webp")}
                    placeholder="blur"
                    width={186}
                    height={30}
                  />
                </Link>
              </div>

              <div className={styles.main_nav}>
                {width > 1140 && (
                  <Fragment>
                    {isLoading ? (
                      <Skeleton width={500} height={70} />
                    ) : (
                      <ul>
                        {menuList &&
                          menuList.length > 0 &&
                          menuList.map((data, index) => {
                            return (
                              <li
                                key={`main_menu${index}`}
                                className={`${
                                  data.isMegaMenu === 1 ? styles.mega : ""
                                } `}
                              >
                                <Link legacyBehavior href={`${data.link}`}>
                                  <a
                                    className={`${
                                      asPath == data.link ? styles.active : ""
                                    }`}
                                  >
                                    {data.title}
                                  </a>
                                </Link>
                                {data.children.length > 0 &&
                                  data.isMegaMenu === 1 && (
                                    <ul
                                      className={`${styles.mega_menu} menu_menu_parent dropdown_parent`}
                                    >
                                      <li>
                                        <div className="container-extend">
                                          <div
                                            className={styles.mega_col_wrapper}
                                          >
                                            {data.children.map(
                                              (megaGroup, indexNumber) => {
                                                return (
                                                  <div
                                                    key={`mega_group${indexNumber}`}
                                                    className={`col ${styles.mega_box}`}
                                                  >
                                                    <div
                                                      className={
                                                        styles.menu_title
                                                      }
                                                    >
                                                      <h5>{megaGroup.title}</h5>
                                                    </div>
                                                    {megaGroup.children.length >
                                                      0 && (
                                                      <div
                                                        className={
                                                          styles.menu_content
                                                        }
                                                      >
                                                        <ul
                                                          className={
                                                            styles.mega_list
                                                          }
                                                        >
                                                          {megaGroup.children.map(
                                                            (
                                                              groupSubmenu,
                                                              groupSubmenuIndex
                                                            ) => {
                                                              return (
                                                                <div
                                                                  key={`groupSubmenu${groupSubmenuIndex}`}
                                                                >
                                                                  <li>
                                                                    <Link
                                                                      legacyBehavior
                                                                      href={`${groupSubmenu.link}`}
                                                                    >
                                                                      <a
                                                                        className={
                                                                          styles.menu_sub_title
                                                                        }
                                                                      >
                                                                        {
                                                                          groupSubmenu.title
                                                                        }
                                                                      </a>
                                                                    </Link>
                                                                  </li>
                                                                  {groupSubmenu
                                                                    .children
                                                                    .length >
                                                                    0 &&
                                                                    groupSubmenu.children.map(
                                                                      (
                                                                        item,
                                                                        i
                                                                      ) => {
                                                                        return (
                                                                          <li
                                                                            key={`subChild${i}`}
                                                                          >
                                                                            <Link
                                                                              legacyBehavior
                                                                              href={
                                                                                item.link
                                                                              }
                                                                            >
                                                                              <a
                                                                                className={
                                                                                  asPath ==
                                                                                  item.link
                                                                                    ? styles.active
                                                                                    : ""
                                                                                }
                                                                              >
                                                                                {
                                                                                  item.title
                                                                                }
                                                                              </a>
                                                                            </Link>
                                                                          </li>
                                                                        );
                                                                      }
                                                                    )}
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </ul>
                                                      </div>
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                {data.children.length > 0 &&
                                  data.isMegaMenu !== 1 && (
                                    <ul className="dropdown_parent">
                                      {data.children.map(
                                        (defaultChild, childIndex) => {
                                          return (
                                            <li
                                              key={`defaultChild${childIndex}`}
                                            >
                                              <Link
                                                legacyBehavior
                                                href={defaultChild.link}
                                              >
                                                <a>
                                                  {defaultChild.title}{" "}
                                                  {defaultChild.children
                                                    .length > 0 && (
                                                    <span className="menu-icon">
                                                      <IoIosArrowForward />
                                                    </span>
                                                  )}
                                                </a>
                                              </Link>
                                              {defaultChild.children.length >
                                                0 && (
                                                <ul>
                                                  {defaultChild.children.map(
                                                    (stepOneSubmenu, i) => {
                                                      return (
                                                        <li
                                                          className="menu-item sub-menu"
                                                          key={`stepOneSubmenu${i}`}
                                                        >
                                                          <Link
                                                            legacyBehavior
                                                            href={
                                                              stepOneSubmenu.link
                                                            }
                                                          >
                                                            <a>
                                                              {
                                                                stepOneSubmenu.title
                                                              }{" "}
                                                              {stepOneSubmenu
                                                                .children
                                                                .length > 0 && (
                                                                <span className="menu-icon">
                                                                  <IoIosArrowForward />
                                                                </span>
                                                              )}
                                                            </a>
                                                          </Link>
                                                          {stepOneSubmenu
                                                            .children.length >
                                                            0 && (
                                                            <ul>
                                                              {stepOneSubmenu.children.map(
                                                                (
                                                                  stepTwoSubmenu,
                                                                  i
                                                                ) => {
                                                                  return (
                                                                    <li
                                                                      className="menu-item sub-menu"
                                                                      key={`stepTwoSubmenu${i}`}
                                                                    >
                                                                      <Link
                                                                        legacyBehavior
                                                                        href={
                                                                          stepTwoSubmenu.link
                                                                        }
                                                                      >
                                                                        <a>
                                                                          {
                                                                            stepTwoSubmenu.title
                                                                          }{" "}
                                                                          {stepTwoSubmenu
                                                                            .children
                                                                            .length >
                                                                            0 && (
                                                                            <span className="menu-icon">
                                                                              <IoIosArrowForward />
                                                                            </span>
                                                                          )}
                                                                        </a>
                                                                      </Link>
                                                                      {stepTwoSubmenu
                                                                        .children
                                                                        .length >
                                                                        0 && (
                                                                        <ul>
                                                                          {stepTwoSubmenu.children.map(
                                                                            (
                                                                              stepThreeSubmenu,
                                                                              i
                                                                            ) => {
                                                                              return (
                                                                                <li
                                                                                  className="menu-item sub-menu"
                                                                                  key={`stepThreeSubmenu${i}`}
                                                                                >
                                                                                  <Link
                                                                                    legacyBehavior
                                                                                    href={
                                                                                      stepThreeSubmenu.link
                                                                                    }
                                                                                  >
                                                                                    <a>
                                                                                      {
                                                                                        stepThreeSubmenu.title
                                                                                      }
                                                                                    </a>
                                                                                  </Link>
                                                                                </li>
                                                                              );
                                                                            }
                                                                          )}
                                                                        </ul>
                                                                      )}
                                                                    </li>
                                                                  );
                                                                }
                                                              )}
                                                            </ul>
                                                          )}
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              )}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  )}
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </Fragment>
                )}
                <HeaderSearch placeholder="Search" />
              </div>

              <div className={styles.right_options}>
                <ul>
                  <li>
                    <FavoriteComponent />
                  </li>
                  <li>
                    <CompareComponent />
                  </li>
                  <Fragment>
                    {(!userData ||
                      Object.keys(userData).length === 0 ||
                      (userData.userTypeSlug == "student" &&
                        !userData.isProfileEvaluated)) && (
                      <li className={styles.btn_profile_evaluation}>
                        <Link legacyBehavior href="/profile/evaluation">
                          <a className={`btn btn-secondary btn-md`}>
                            Profile Evaluation
                          </a>
                        </Link>
                      </li>
                    )}
                  </Fragment>

                  <Fragment>
                    {userData && Object.keys(userData).length > 0 ? (
                      <li>
                        <AccountMenu userInfo={userData} />
                      </li>
                    ) : (
                      <li className="desktop-icon">
                        <Link legacyBehavior href="/signin">
                          <a
                            className={`btn btn-outline btn-md ${styles.btn_signin}`}
                          >
                            <span className={styles.open_desktop}>Sign In</span>
                            <MdPerson className={styles.open_mobile} />
                          </a>
                        </Link>
                      </li>
                    )}
                  </Fragment>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      {width < 1140 && (
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={false}
          ModalProps={{ keepMounted: true }}
        >
          <HeaderMobileOne menuData={menuData} setOpen={setOpen} />
        </SwipeableDrawer>
      )}
    </Fragment>
  );
};

export default HeaderOne;
